import { Component, OnInit, OnDestroy, Input, ElementRef, ViewChild } from '@angular/core';
import { Location } from '@angular/common';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { Observable, Subject, Subscription, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';

import { HistoryService } from '../../../services/history/history.service';
import { IoService } from '../../../services/io/io.service';

@Component({
  selector: 'app-detail-location',
  templateUrl: './detail-location.component.html',
  styleUrls: ['./detail-location.component.scss']
})
export class DetailLocationComponent implements OnInit {

  documentId$: Observable<any>;
  documentId;

  showBackButton;

  location;
  creditLedger;
  saving;
  savingCredit;
  importingTransactions;

  closingBatch;

  addingApiKey;
  apiKey;

  importingFromRemote;

  smsCreditBalance;
  smsCreditAmount;
  smsCreditLedgerId;

  emailCreditBalance;
  emailCreditAmount;
  emailCreditLedgerId;

  timeZoneOptions = [
    {
      name: 'Eastern',
      value: 'America/New_York'
    },
    {
      name: 'Central',
      value: 'America/Chicago'
    },
    {
      name: 'Mountain',
      value: 'America/Denver'
    },
    {
      name: 'Pacific',
      value: 'America/Los_Angeles'
    }
  ];

  batchTimeOptions = [
    '',
    '8:00 PM',
    '8:30 PM',
    '9:00 PM',
    '9:30 PM',
    '10:00 PM',
    '10:30 PM',
    '10:40 PM',
    '10:45 PM',
    '10:50 PM',
    '11:00 PM',
    '11:55 PM',
    '12:00 AM',
    '12:55 AM',
    '1:00 AM',
    '1:55 AM',
    '2:00 AM',
    '3:00 AM',
    '4:00 AM',
    '5:00 AM',
    '6:00 AM',
    '7:00 AM',
    '8:00 AM',
    '9:00 AM',
    '10:00 AM',
    '11:00 AM',
    '12:00 PM',
    '1:00 PM',
    '2:00 PM',
    '3:00 PM',
    '4:00 PM',
    '5:00 PM',
    '6:00 PM',
    '7:00 PM'
  ];

  citygroConfig;

  @ViewChild('import', { static: false }) importEl: ElementRef<HTMLElement>;
  @ViewChild('citygro', { static: false}) citygroEl: ElementRef<HTMLElement>;

  constructor(
    private locationA: Location,
    private route: ActivatedRoute,
    private router: Router,
    private historyService: HistoryService,
    private ioService: IoService
  ) { }

  ngOnInit() {

    this.documentId$ = this.route.paramMap.pipe(switchMap(params => of(params.get('documentId'))));
    this.documentId$.subscribe(documentId => {
      if (documentId) {
        this.documentId = documentId;
        this.loadLocationDetail();
      }
    });

    if (this.historyService.getPreviousLinkDepth() > 3) {
      this.showBackButton = true;
    }
  }

  close() {
    this.router.navigate(['../../'], { relativeTo: this.route });
  }

  back() {
    this.locationA.back();
  }

  loadLocationDetail() {
    return new Promise((resolve, reject) => {
      this.location = null;
      this.creditLedger = null;
      this.smsCreditBalance = null
      this.emailCreditBalance = null;


      this.addingApiKey = null;
      this.apiKey = null;


      this.ioService.post('/location/getDetails', {
        locationId: this.documentId
      }).then((locationResponse: any) => {
        console.log('locationResponse: ');
        console.log(locationResponse);
        if (!locationResponse.location.address) {
          locationResponse.location.address = {};
        }
        this.location = locationResponse.location;

        this.smsCreditBalance = locationResponse.smsCreditBalance;
        this.smsCreditLedgerId = locationResponse.smsCreditLedgerId;

        this.emailCreditBalance = locationResponse.emailCreditBalance;
        this.emailCreditLedgerId = locationResponse.emailCreditLedgerId;

        if (locationResponse.creditLedger) {
          if (!locationResponse.creditLedger.batchEmailAddresses) {
            locationResponse.creditLedger.batchEmailAddresses = [];
          }

          if (!locationResponse.creditLedger.receiptEmailAddresses) {
            locationResponse.creditLedger.receiptEmailAddresses = [];
          }

          this.creditLedger = locationResponse.creditLedger
        }

        this.citygroConfig = {
          importing: false,
          pointMethod: 'remainder'
        };

        console.log('citygro: ', this.citygroConfig);

      });
    });
  }

  saveChanges() {
    console.log('saveChanges: ', this.location);
    this.saving = true;
    this.location.custom = null;
    this.ioService.post('/location/saveChanges', this.location).then((saveResponse: any) => {
      console.log('saved.');
      this.saving = false;
    });
  }

  trackByIndex(index: number, obj: any): any {
    return index;
  }

  addBatchEmailAddress() {
    this.creditLedger.batchEmailAddresses.push('');
  }

  addReceiptEmailAddress() {
    this.creditLedger.receiptEmailAddresses.push('');
  }

  deleteBatchEmail(index) {
    this.creditLedger.batchEmailAddresses.splice(index, 1);
  }

  deleteReceiptEmail(index) {
    this.creditLedger.receiptEmailAddresses.splice(index, 1);
  }

  saveCreditChanges() {
    console.log('saveCreditChanges: ', this.creditLedger);
    this.savingCredit = true;
    this.ioService.post('/location/saveCreditChanges', {
      locationId: this.location._id,
      batchEmailAddresses: this.creditLedger.batchEmailAddresses,
      receiptEmailAddresses: this.creditLedger.receiptEmailAddresses,
      customerReceipt: this.creditLedger.customerReceipt,
      processor: this.creditLedger.processor
    }).then((saveResponse: any) => {
      console.log('saved cc config.');
      this.savingCredit = false;
    });
  }

  importTransactions() {
    let el: HTMLElement = this.importEl.nativeElement;
    el.click();
  }

  onFileChange(event) {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      this.ioService.upload('/import/transactions', {
        locationId: this.documentId
      }, file).subscribe(event => {
        console.log(event);
      });
    }
  }

  createApiUserKey() {
    this.addingApiKey = true;
    this.ioService.post('/location/createApiUserKey', {
      locationId: this.location._id
    }).then((createResponse: any) => {
      console.log('createResponse: ', createResponse);
      this.apiKey = createResponse.apiKey;
      // this.addingApiKey = false;
    });
  }

  remoteExport() {
    if (!this.importingFromRemote) {
      this.importingFromRemote = true;
      this.ioService.post('/boardandbrush/remoteExport', {
        locationId: this.location._id,
        minId: 0,
        limit: 5000
      }).then((result: any) => {
        this.importingFromRemote = false;

      }).catch((err: any) => {
        console.log(err);
      });
    }
  }

  closeBatch() {
    if (!this.closingBatch) {
      this.closingBatch = true;
      this.ioService.post('/batch/closeBatch', {
        locationId: this.location._id
      }).then((result: any) => {
        this.closingBatch = false;

      }).catch((err: any) => {
        console.log(err);
      });
    }
  }

  subtractSmsCredits() {
    console.log('subtractSmsCredits: ', this.smsCreditAmount);
    this.ioService.post('/transaction/resource/subtract', {
      locationId: this.location._id,
      ledgerId: this.smsCreditLedgerId,
      amount: this.smsCreditAmount/100
    }).then((subtractResponse: any) => {
      console.log('subtractResponse: ', subtractResponse);
      this.loadLocationDetail();
    });
  }

  addSmsCredits() {
    console.log('addSmsCredits: ', this.smsCreditAmount);
    this.ioService.post('/transaction/resource/add', {
      locationId: this.location._id,
      ledgerId: this.smsCreditLedgerId,
      amount: this.smsCreditAmount/100
    }).then((addResponse: any) => {
      console.log('addResponse: ', addResponse);
      this.loadLocationDetail();
    });
  }

  subtractEmailCredits() {
    console.log('subtractEmailCredits: ', this.emailCreditAmount);
    this.ioService.post('/transaction/resource/subtract', {
      locationId: this.location._id,
      ledgerId: this.emailCreditLedgerId,
      amount: this.emailCreditAmount/100
    }).then((subtractResponse: any) => {
      console.log('subtractResponse: ', subtractResponse);
      this.loadLocationDetail();
    });
  }

  addEmailCredits() {
    console.log('addEmailCredits: ', this.emailCreditAmount);
    this.ioService.post('/transaction/resource/add', {
      locationId: this.location._id,
      ledgerId: this.emailCreditLedgerId,
      amount: this.emailCreditAmount/100
    }).then((addResponse: any) => {
      console.log('addResponse: ', addResponse);
      this.loadLocationDetail();
    });
  }


  importCityGro() {
    let el: HTMLElement = this.citygroEl.nativeElement;
    el.click();
  }

  onCityGroFileChange(event) {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      this.ioService.upload('/import/citygro', {
        locationId: this.documentId,
        pointMethod: this.citygroConfig.pointMethod
      }, file).subscribe(event => {
        console.log(event);

      });
    }
  }

}
