import { Component, OnInit, OnDestroy, ViewChild, ChangeDetectorRef } from '@angular/core';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import { Observable, Subject, Subscription, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { filter } from 'rxjs/operators';
import { ScrollDispatcher } from '@angular/cdk/scrolling';

import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { DragulaService } from 'ng2-dragula';

import { IoService } from '../../../services/io/io.service';
import { ReportService } from '../../../services/report/report.service';
import { AuthorizationService } from '../../../services/authorization/authorization.service';
import { LocationService } from '../../../services/location/location.service';

import { AddLocationComponent } from '../add-location/add-location.component';
import { AddReportComponent } from '../add-report/add-report.component';
import { AddUserComponent } from '../add-user/add-user.component';
import { AddInvoiceComponent } from '../add-invoice/add-invoice.component';

@Component({
  selector: 'app-report-data',
  templateUrl: './report-data.component.html',
  styleUrls: ['./report-data.component.scss']
})
export class ReportDataComponent implements OnInit, OnDestroy {
  @ViewChild(CdkVirtualScrollViewport, { static: false })
  public viewport: CdkVirtualScrollViewport;

  reportAlias$: Observable<any>;
  alias;

  activeReportConfig;

  tableWidth = 0;
  template;
  availableFields;
  activeFields;
  records;
  totals;

  fieldAddOpen;
  fieldsSort: Subscription;

  canAdd: boolean = false;
  adding: boolean = false;

  // On active report set, see if we can add based on the active location

  reportSelected: Subscription;
  reportLoaded: Subscription;
  activeLocationSet: Subscription;
  // sortSet: Subscription;
  permissionsLoaded: Subscription;

  tsl: number = 0;

  records$: Observable<any[]>;

  activeDocumentId;

  modalRef;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private reportService: ReportService,
    private authorizationService: AuthorizationService,
    private locationService: LocationService,
    private tableScroll: ScrollDispatcher,
    private changeDetectorRef: ChangeDetectorRef,
    public dialog: MatDialog,
    private dragulaService: DragulaService,
    private ioService: IoService
  ) { }

  ngOnInit() {
    this.permissionsLoaded = this.authorizationService.permissionsLoadedObservable().subscribe(loaded => {
      this.checkCanAdd();
    });

    this.reportSelected = this.reportService.reportSelectedObservable().subscribe(reportConfig => {
      this.activeReportConfig = reportConfig;
    });

    this.reportLoaded = this.reportService.reportLoadedObservable().subscribe(reportMeta => {
      this.activeFields = reportMeta.activeFields;


      this.tableWidth = 0;
      for (let field of this.activeFields) {
        this.tableWidth += field.width;
      }

      this.template = reportMeta.template;
      this.totals = reportMeta.totals;
      this.viewport.scrollToIndex(0);

      this.setAvailableFields();

      this.checkCanAdd();
    });

    this.records$ = this.reportService.recordsUpdatedObservable();

    this.tableScroll.scrolled().subscribe(x => {
      if (x) {
        let tableScrollLeft = x.getElementRef().nativeElement.scrollLeft;
        if (this.tsl != tableScrollLeft) {
          this.tsl = x.getElementRef().nativeElement.scrollLeft;
          this.changeDetectorRef.detectChanges();
        }
      }
    });

    this.activeReportConfig = this.reportService.getActiveReportConfig();

    this.reportAlias$ = this.route.paramMap.pipe(
      switchMap(params =>
        of(params.get('alias'))
      )
    );

    this.reportAlias$.subscribe(alias => {
      if (alias) {
        this.setReportByAlias(alias);
      }
    });

    this.fieldsSort = this.dragulaService.drop('fields').subscribe(({ name, el, target, source, sibling }) => {
      this.reportService.setFieldsSequence(this.activeFields);
    });

    // Listen to routes to get active documentId
    this.setActiveDocumentByPath(this.router.url);
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe((event: NavigationEnd) => {
      this.setActiveDocumentByPath(event.url);
    });
  }

  ngOnDestroy() {
    // this.reportAlias$.unsubscribe();
    this.permissionsLoaded.unsubscribe();
    this.fieldsSort.unsubscribe();
  }

  setActiveDocumentByPath(path) {
    let pathParts = path.split('/');
    if (pathParts.length == 5) {
      this.activeDocumentId = pathParts[4];
    } else {
      this.activeDocumentId = null;
    }
  }

  setReportByAlias(alias) {
    this.alias = alias;
    this.reportService.setReportByAlias(alias);
  }


  checkCanAdd() {
    if (this.activeReportConfig) {
      this.canAdd = false;
      if (this.activeReportConfig.addPermission) {

        if (this.activeReportConfig.locationPath) {
          // This permission is location specific
          if (this.authorizationService.checkPermission(this.activeReportConfig.addPermission, this.locationService.getActiveLocation()._id)) {
            // console.log('Authorized');
            this.canAdd = true;
          }
        } else {
          // This permission is NOT location specific
          console.log('PERMISSION IS NOT LOCATION SPECIFIC: ', this.activeReportConfig.addPermission);
          if (this.authorizationService.checkPermission(this.activeReportConfig.addPermission)) {
            this.canAdd = true;
          }
        }
      } else {
        // console.log('No addPermission for the active report');
      }
    }
  }

  add() {
    this.reportService.setAdding(true);

    let addComponent;
    switch (this.activeReportConfig.itemType) {
      case 'location':
        addComponent = AddLocationComponent;
        break;

      case 'report':
        addComponent = AddReportComponent;
        break;

      case 'user':
        addComponent = AddUserComponent;
        break;

      case 'transaction':
        console.log('Add Transaction');
        addComponent = AddInvoiceComponent;
        break;

      case 'inventory':
        console.log('ADD INVENTORY ITEM');
        this.ioService.post('/item/addItem', {
          locationId: this.locationService.getActiveLocation()._id
        }).then((itemResponse: any) => {
          console.log('itemResponse: ', itemResponse);
          this.router.navigate(['reports', this.activeReportConfig.alias, this.activeReportConfig.itemType, itemResponse.itemId]);
        });
        break;

      case 'tax':
        console.log('ADD TAX OPTION');
        this.ioService.post('/tax/addTax', {
          locationId: this.locationService.getActiveLocation()._id
        }).then((itemResponse: any) => {
          console.log('itemResponse: ', itemResponse);
          this.router.navigate(['reports', this.activeReportConfig.alias, this.activeReportConfig.itemType, itemResponse.itemId]);
        });
        break;

      case 'permission':
        console.log('ADD PERMISSION OPTION');
        this.ioService.post('/permission/addPermission', {}).then((permissionResponse: any) => {
          console.log('permissionResponse: ', permissionResponse);
          this.router.navigate(['reports', this.activeReportConfig.alias, this.activeReportConfig.itemType, permissionResponse.permissionId]);
        });
        break;

      case 'group':
        console.log('ADD GROUP OPTION');
        this.ioService.post('/group/addGroup', {}).then((groupResponse: any) => {
          console.log('groupResponse: ', groupResponse);
          this.router.navigate(['reports', this.activeReportConfig.alias, this.activeReportConfig.itemType, groupResponse.groupId]);
        });
        break;

      case 'employee':
        console.log('ADD EMPLOYEE');
        this.ioService.post('/user/addEmployee', {
          locationId: this.locationService.getActiveLocation()._id
        }).then((userResponse: any) => {
          console.log('userResponse: ', userResponse);
          this.router.navigate(['reports', this.activeReportConfig.alias, this.activeReportConfig.itemType, userResponse.userId]);
        });
        break;
    }

    if (addComponent) {
      this.modalRef = this.dialog.open(addComponent, {
        height: '400px',
        width: '600px'
      });

      this.modalRef.afterClosed().subscribe(newId => {
        if (newId) {
          this.reportService.loadReport(0);
          this.router.navigate(['reports', this.activeReportConfig.alias, this.activeReportConfig.itemType, newId]);
        }
      });
    }
  }

  setDetailDocumentId(recordId) {
    this.router.navigate(['reports', this.activeReportConfig.alias, this.activeReportConfig.itemType, recordId]);
  }

  setAvailableFields() {
    this.availableFields = [];
    for (let potentialField of this.template.fields) {
      if (this.activeFields.indexOf(potentialField) == -1) {
        this.availableFields.push(potentialField);
      }
    }
  }

  toggleFieldAdd() {
    this.fieldAddOpen = !this.fieldAddOpen;
  }

  addField(field) {
    this.reportService.addField(field);
  }

  removeField(event: Event, field) {
    event.stopPropagation();
    let activeIndex = this.activeFields.indexOf(field);
    if (activeIndex > -1) {
      this.activeFields.splice(activeIndex, 1);
    }
    this.reportService.removeField(field);
  }

  downloadReport() {
    this.reportService.download();
  }

  setSearch(event: any) {
    this.reportService.setSearch(event.target.value);
  }

  setSort(field) {
    this.reportService.setSort(field);
  }

  reportScrolled(topRecordIndex) {
    this.reportService.loadChunk(topRecordIndex);
  }

  trackByIndex(index: number) {
    return index;
  }

  getValueByPath(o, path) {
    if (o == null) {
      return;
    } else {
      path = path.replace(/\[(\w+)\]/g, '.$1'); // convert indexes to properties
      path = path.replace(/^\./, '');           // strip a leading dot
      let a = path.split('.');
      for (let i = 0, n = a.length; i < n; ++i) {
          var k = a[i];
          if (k in o) {
              o = o[k];
          } else {
              return;
          }
      }
      return o;
    }
  }

  refreshReport() {
    this.reportService.refresh();
  }




}
