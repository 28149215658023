import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { Observable, Subject, Subscription, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';

import { HistoryService } from '../../../services/history/history.service';
import { IoService } from '../../../services/io/io.service';
import { AuthorizationService } from '../../../services/authorization/authorization.service';

@Component({
  selector: 'app-detail-user',
  templateUrl: './detail-user.component.html',
  styleUrls: ['./detail-user.component.scss']
})
export class DetailUserComponent implements OnInit {

    documentId$: Observable<any>;
    documentId;

    showBackButton;

    user;
    savingUser;

    canGenerateApiKey;
    inviteSent;

    newPin;

    manageUsersLocations;
    location;
    locationPermissions;
    locationPermissionOptions = [
      {
        name: 'Admin',
        permission: 'location.admin',
        authorized: false
      },
      {
        name: 'Corporate Group',
        permission: 'location.group.corp',
        authorized: false
      },
      {
        name: 'Edit',
        permission: 'location.edit',
        authorized: false
      },
      {
        name: 'Dashboard',
        permission: 'location.dashboard',
        authorized: false
      },
      {
        name: 'Manage Users',
        permission: 'location.manageUsers',
        authorized: false
      },
      {
        name: 'View Reports',
        permission: 'location.report',
        authorized: false
      },
      {
        name: 'Purchase',
        permission: 'location.purchase',
        authorized: false
      },
      {
        name: 'Communicate',
        permission: 'location.communicate',
        authorized: false
      },
      {
        name: 'Com Suite',
        permission: 'location.comsuite'
      },
      {
        name: 'Manage Customers',
        permission: 'location.manageCustomers',
        authorized: false
      },
      {
        name: 'Manage Inventory',
        permission: 'location.manageInventory',
        authorized: false,
      },
      {
        name: "Manage Taxes",
        permission: 'location.manageTaxes',
        authorized: false
      },
      {
        name: 'Adjust Time Clock',
        permission: 'location.timeClock',
        authorized: false
      },
      {
        name: 'Remote Purchase',
        permission: 'location.remotePurchase',
        authorized: false
      },
      {
        name: 'Fulfillment',
        permission: 'location.fulfillment',
        authorized: false
      },
      {
        name: 'ISE Pre-Auth',
        permission: 'location.ise.preauth',
        authorized: false
      },
      {
        name: 'ISE Pin-In',
        permission: 'location.ise.pinin',
        authorized: false
      },
      {
        name: 'Virtual Terminal',
        permission: 'location.virtualTerminal',
        authorized: false
      },
      {
        name: 'Credit Card Refund',
        permission: 'location.ledger.credit.add',
        authorized: false
      },
      {
        name: 'Credit Card Sale',
        permission: 'location.ledger.credit.subtract',
        authorized: false
      },
      {
        name: 'Credit Card Void',
        permission: 'location.ledger.credit.void',
        authorized: false
      },
      {
        name: 'Credit Card Request Token',
        permission: 'location.ledger.credit.check',
        authorized: false
      },
      {
        name: 'Credit Card API Credentials',
        permission: 'location.ledger.credit.api',
        authorized: false
      },
      {
        name: 'Credit Card Receipt Send',
        permission: 'location.ledger.credit.receipt',
        authorized: false
      },
      {
        name: 'Gift Card Activate',
        permission: 'location.ledger.gift.add',
        authorized: false
      },
      {
        name: 'Gift Card Charge',
        permission: 'location.ledger.gift.subtract',
        authorized: false
      },
      {
        name: 'Gift Card Balance Check',
        permission: 'location.ledger.gift.check',
        authorized: false
      },
      {
        name: 'E-Invoice Create',
        permission: 'location.ledger.invoice.add',
        authorized: false
      },
      {
        name: 'E-Invoice Cancel',
        permission: 'location.ledger.invoice.subtract',
        authorized: false
      },
      {
        name: 'E-Invoice Lookup',
        permission: 'location.ledger.invoice.check',
        authorized: false
      },
      {
        name: 'Cash Refund',
        permission: 'location.ledger.cash.add',
        authorized: false
      },
      {
        name: 'Cash Sale',
        permission: 'location.ledger.cash.subtract',
        authorized: false
      },
      {
        name: 'Cash Balance Check',
        permission: 'location.ledger.cash.check',
        authorized: false
      },
      {
        name: 'Discount Create',
        permission: 'location.ledger.discount.add',
        authorized: false
      },
      {
        name: 'Discount Apply',
        permission: 'location.ledger.discount.subtract',
        authorized: false
      },
      {
        name: 'Discount Check',
        permission: 'location.ledger.discount.check',
        authorized: false
      },
      {
        name: 'Loyalty Grant Points',
        permission: 'location.ledger.loyalty.add',
        authorized: false
      },
      {
        name: 'Loyalty Revoke Points',
        permission: 'location.ledger.loyalty.subtract',
        authorized: false
      },
      {
        name: 'Loyalty Balance Check',
        permission: 'location.ledger.loyalty.check',
        authorized: false
      },
      {
        name: 'Checkin Add',
        permission: 'location.ledger.checkin.add',
        authorized: false
      },
      {
        name: 'Checkin Remove',
        permission: 'location.ledger.checkin.subtract',
        authorized: false
      },
      {
        name: 'Checkin Check',
        permission: 'location.ledger.checkin.check',
        authorized: false
      },
      {
        name: 'Agreement Accept',
        permission: 'location.ledger.agreement.add',
        authorized: false
      },
      {
        name: 'Agreement Decline',
        permission: 'location.ledger.agreement.subtract',
        authorized: false
      },
      {
        name: 'Agreement Check',
        permission: 'location.ledger.agreement.check',
        authorized: false
      },
      {
        name: 'Reward Add',
        permission: 'location.ledger.reward.add',
        authorized: false
      },
      {
        name: 'Reward Subtract',
        permission: 'location.ledger.reward.subtract',
        authorized: false
      },
      {
        name: 'Reward Check',
        permission: 'location.ledger.reward.check',
        authorized: false
      },
      {
        name: 'Month End Submit',
        permission: 'location.form.submit',
        authorized: false
      },
      {
        name: 'Month End Approve',
        permission: 'location.form.approve',
        authorized: false
      }
    ];

    locationAssignablePermissions;

    dynamicPermissionGroups;

    private permissionsLoaded: Subscription;
    private detailDocumentSet: Subscription;

    constructor(
      private locationR: Location,
      private route: ActivatedRoute,
      private router: Router,
      private historyService: HistoryService,
      private authorizationService: AuthorizationService,
      private ioService: IoService
    ) { }

    ngOnInit() {
      this.documentId$ = this.route.paramMap.pipe(switchMap(params => of(params.get('documentId'))));
      this.documentId$.subscribe(documentId => {
        if (documentId) {
          this.documentId = documentId;
          // this.loadUserDetail();
          this.init();
        }
      });

      if (this.historyService.getPreviousLinkDepth() > 3) {
        this.showBackButton = true;
      }
    }

    close() {
      this.router.navigate(['../../'], { relativeTo: this.route });
    }

    back() {
      this.locationR.back();
    }

    init(location=null) {
      // this.authorizationService.checkPermission('manageUsers', target=null)
      console.log('user init');
      this.newPin = null;
      this.canGenerateApiKey = null;
      this.loadUserDetail();
      this.location = null; // May be defined by input to init
      this.locationPermissions = null;
      if (location) {
        this.setLocation(location);
      }
    }

    loadUserDetail() {
      this.user = null;
      this.ioService.post('/user/getDetails', {
        userId: this.documentId
      }).then((userResponse: any) => {

        this.manageUsersLocations = userResponse.manageUsersLocations;
        this.user = userResponse.user;

        // console.log('user: ', this.user);
        // console.log('manageUsersLocations: ', this.manageUsersLocations);

        if (this.authorizationService.checkPermission('Location.add')) {
          this.canGenerateApiKey = true;
        }

      }).catch((error: any) => {
        this.back();
      });
    }

    setLocation(location) {
      console.log('setLocation: ', location);
      return new Promise((resolve, reject) => {
        this.location = null;
        this.locationPermissions = null;
        this.ioService.post('/user/getLocationPermissions', {
          userId: this.documentId,
          locationId: location._id
        }).then((permissionResponse: any) => {
          console.log('permissionResponse: ', permissionResponse);
          this.locationPermissions = permissionResponse.granted;
          this.location = location;

          this.dynamicPermissionGroups = permissionResponse.permissionGroups;
          console.log('dynamicPermissionGroups: ', this.dynamicPermissionGroups);

          this.locationAssignablePermissions = [];
          if (this.locationPermissions) {
            for (let permission of this.locationPermissionOptions) {
              if (this.authorizationService.checkPermission(permission.permission, location._id)) {
                // The active user has this permission on this location
                if (this.locationPermissions.indexOf(permission.permission) != -1) {
                  permission.authorized = true;
                } else {
                  permission.authorized = false;
                }

                this.locationAssignablePermissions.push(permission);
              }
            }

            for (let reportPermission of permissionResponse.availableReports) {
              let permission = {
                name: 'Report: '+reportPermission.name,
                permission: 'location.report.'+reportPermission._id,
                authorized: false
              };
              // The active user has this permission on this location
              if (this.locationPermissions.indexOf(permission.permission) != -1) {
                permission.authorized = true;
              } else {
                permission.authorized = false;
              }
              this.locationAssignablePermissions.push(permission);
            }

          }

          resolve();
        });
      });
    }

    addUserLocation(location) {
      console.log('addUserLocation: '+location);
      this.ioService.post('/user/addUserLocation', {
        userId: this.documentId,
        locationId: location._id
      }).then((userId: any) => {
        console.log('addUserResponse: ', userId);

        this.init(location);
      });
    }

    removeUserLocation(locationId) {
      console.log('removeUserLocation: '+locationId);
      this.ioService.post('/user/removeUserLocation', {
        userId: this.documentId,
        locationId: this.location._id
      }).then((userId: any) => {
        console.log('removeUserResponse: ', userId);
        this.init();
      });
    }

    togglePermission(permission) {
      if (permission.authorized) {
        // Deauthorize
        console.log('Deauthorize: '+permission.name);
        this.ioService.post('/user/deauthorize', {
          userId: this.documentId,
          locationId: this.location._id,
          permission: permission.permission
        }).then(() => {
          // this.init(this.location);
          permission.authorized = false;
        });
      } else {
        // Authorize
        console.log('Authorize: '+permission.name);
        this.ioService.post('/user/authorize', {
          userId: this.documentId,
          locationId: this.location._id,
          permission: permission.permission
        }).then(() => {
          // this.init(this.location);
          permission.authorized = true;
        });
      }
    }

    setPermissionGroup(permissionGroupName) {
      console.log('setPermissionGroup: ', permissionGroupName);
      this.ioService.post('/user/authorizeGroup', {
        userId: this.documentId,
        locationId: this.location._id,
        groupName: permissionGroupName
      }).then(() => {
        this.init(this.location);
      });
    }

    applyPermissionGroup(permissionGroupName) {
      console.log('applyPermissionGroup: ', permissionGroupName);

      this.ioService.post('/user/applyPermissionGroup', {
        userId: this.documentId,
        locationId: this.location._id,
        groupName: permissionGroupName
      }).then(() => {
        this.init(this.location);
      });
    }

    sendPasswordResetSms() {
      console.log('sendPasswordResetSms: ');
      // this.ioService.post('/user/sendPasswordResetSms', {
      //   userId: this.documentId
      // }).then((resetResponse: any) => {
      //   console.log('resetResponse: ', resetResponse);
      //
      // });
      this.inviteSent = true;
      this.ioService.post('/authentication/requestPasswordReset', {
        userKey: this.user.telephone
      }).then((resetResponse: any) => {
          console.log('resetResponse: ', resetResponse);
      });
    }

    sendPasswordResetEmail() {
      console.log('sendPasswordResetEmail: ');
      this.inviteSent = true;
      this.ioService.post('/authentication/requestPasswordReset', {
        userKey: this.user.email
      }).then((resetResponse: any) => {
          console.log('resetResponse: ', resetResponse);
      });
    }

    generateApiKey() {
      console.log('generateApiKey: ');
      this.canGenerateApiKey = false;
      this.ioService.post('/authentication/setApiKey', {
        userId: this.user._id
      }).then((apiKeyResponse: any) => {
        console.log('apiKeyResponse: ', apiKeyResponse);
      });
    }

    generatePin() {
      console.log('generatePIN');
      this.ioService.post('/authentication/setPin', {
        userId: this.user._id
      }).then((pinResponse: any) => {
        console.log('pinResponse: ', pinResponse);
        this.newPin = pinResponse.pin;
      });
    }

    saveChanges() {
      if (!this.savingUser) {
        this.savingUser = true;
        this.ioService.post('/user/saveChanges', {
          user: this.user
        }).then((saveResponse: any) => {
          this.savingUser = false;
        }).catch((error: any) => {
          console.log('ERROR: '+error);
          alert('Error Saving User');
          this.savingUser = false;
        });
      }
    }

}
