import { Component, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA} from '@angular/material';

import { IoService } from '../../../services/io/io.service';

@Component({
  selector: 'app-add-report',
  templateUrl: './add-report.component.html',
  styleUrls: ['./add-report.component.scss']
})
export class AddReportComponent implements OnInit {

  templateFilename: string;
  validName: boolean;

  constructor(
    public dialogRef: MatDialogRef<AddReportComponent>,
    private ioService: IoService
  ) { }

  ngOnInit() {
  }

  setTemplateFilename(event: any) {
    this.templateFilename = event.target.value;
    if (this.templateFilename.length > 4) {
      this.validName = true;
    }
  }

  importReportTemplate() {
    this.ioService.post('/report/importTemplate', {
      templateFilename: this.templateFilename
    }).then((reportId: any) => {
      this.dialogRef.close(reportId);
    });
  }

}
