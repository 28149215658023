import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { Observable, Subject, Subscription, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';

import { HistoryService } from '../../../services/history/history.service';
import { IoService } from '../../../services/io/io.service';
import { ReportService } from '../../../services/report/report.service';

@Component({
  selector: 'app-detail-tax',
  templateUrl: './detail-tax.component.html',
  styleUrls: ['./detail-tax.component.scss']
})
export class DetailTaxComponent implements OnInit {

  documentId$: Observable<any>;
  documentId;

  showBackButton;

  tax;
  saving;

  roundingOptions = [
    {
      name: 'Round Up',
      value: 'roundUp'
    },
    {
      name: 'Round Half Up',
      value: 'roundHalfUp'
    }
  ];

  basisOptions = [
    {
      name: 'Item-Level',
      value: 'item'
    },
    {
      name: 'Invoice-Level',
      value: 'invoice'
    }
  ];

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private ioService: IoService,
    private historyService: HistoryService,
    private reportService: ReportService
  ) { }

  ngOnInit() {
    this.documentId$ = this.route.paramMap.pipe(switchMap(params => of(params.get('documentId'))));
    this.documentId$.subscribe(documentId => {
      if (documentId) {
        this.documentId = documentId;
        this.loadTaxDetail();
      }
    });

    if (this.historyService.getPreviousLinkDepth() > 3) {
      this.showBackButton = true;
    }
  }

  close() {
    this.router.navigate(['../../'], { relativeTo: this.route });
  }

  loadTaxDetail() {
    this.ioService.post('/tax/getTax', {
      taxId: this.documentId
    }).then((taxResponse: any) => {
      console.log('taxResponse: ', taxResponse);

      this.tax = taxResponse.tax;

    });
  }

  saveTax() {
    if (!this.saving) {
      console.log('save tax');
      this.saving = true;

      let updateTax = JSON.parse(JSON.stringify(this.tax));

      this.ioService.post('/tax/updateTax', {
        tax: updateTax
      }).then((updateResponse: any) => {
        console.log('updateResponse: ', updateResponse);
        this.saving = false;
        this.reportService.loadReport(0);
      });
    }
  }

}
