import { Component, OnInit, ElementRef, ViewChild, HostListener } from '@angular/core';
import { Observable, Subject, Subscription, of } from 'rxjs';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { switchMap } from 'rxjs/operators';

import { IoService } from '../../services/io/io.service';
import { LocationService } from '../../services/location/location.service';
import { AuthorizationService } from '../../services/authorization/authorization.service';
import { environment } from '../../../environments/environment';
import { DomSanitizer } from '@angular/platform-browser';


@Component({
  selector: 'app-purchase',
  templateUrl: './purchase.component.html',
  styleUrls: ['./purchase.component.scss']
})
export class PurchaseComponent implements OnInit {

  @ViewChild('items', { static: false }) private itemsContainer: ElementRef;
  @ViewChild('purchasenotes', { static: false }) private purchaseNotes: ElementRef;
  @ViewChild('purchasetip', { static: false }) private purchaseTipElement: ElementRef;
  @ViewChild('itemnotes', { static: false }) private itemNotesElement: ElementRef;
  @ViewChild('itemprice', { static: false }) private itemPriceElement: ElementRef;




  @HostListener('window:message', ['$event']) onMessage(e) {
     console.log('message posted to window');
     console.log(e.data);

     if (e.data == 'tokenized') {
       this.purchase.tokenized = true;
       this.validateCreditCard();
     }

   }




  private activeLocationSet: Subscription;
  private permissionsLoaded: Subscription;

  activeLocation;

  purchaseId$: Observable<any>;
  purchaseId;

  purchase;

  purchases;

  inventory;
  inventoryIndexed;
  categories;

  taxes;
  taxesInvoice = [];
  taxesItem = [];

  tintOn;
  tintActive;

  leftOpen;
  leftTimeout;
  leftView;

  rightOpen;
  rightTimeout;
  rightView;

  activeLineItem;

  purchaseSyncActive;
  purchaseSyncQueue;


  // Holder Variables for modifications
  lineItemPrice;
  purchaseTip;

  invoiceEmail;
  invoiceEmailValid;
  invoiceNotes;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private ioService: IoService,
    private locationService: LocationService,
    private authorizationService: AuthorizationService,
    public sanitizer: DomSanitizer
  ) {

  }

  ngOnInit() {

  /*
    Load permissions

    See if a location is already selected

    See if a purchaseId is already specified


  */

    this.permissionsLoaded = this.authorizationService.permissionsLoadedObservable().subscribe(() => {
      console.log('permissionsLoaded');
      this.checkPurchaseState()
    });

    if (this.authorizationService.permissionsAreLoaded()) {
      console.log('permissions already loaded');
      this.checkPurchaseState();
    }

    console.log('purchase INIT:');
  }

  ngOnDestroy() {
    if (this.activeLocationSet) {
      this.activeLocationSet.unsubscribe();
    }
  }

  arrayToObject = (array, keyField) => array.reduce((obj, item) => {
    obj[item[keyField]] = item;
    return obj;
  }, {});

  checkPurchaseState() {
    console.log('checkPurchaseState');
    // Listen for the Purchase
    this.purchaseId$ = this.route.paramMap.pipe(switchMap(params => of(params.get('purchaseId'))));
    this.purchaseId$.subscribe(purchaseId => {

      if (purchaseId) {
        console.log('purchaseId is defined');
        this.purchaseId = purchaseId;
        this.loadPurchase();
      } else {
        console.log('purchaseId is NOT defined');
        this.activeLocation = this.locationService.getActiveLocation();
        if (this.activeLocation) {
          // An active location is defined, go ahead and create a new purchase
          this.newPurchase();
        } else {
          // There is no active location set yet

        }
      }
    });


    // As soon as an active location is set, go ahead and generate a new purchase
    this.activeLocationSet = this.locationService.activeLocationSetObservable().subscribe(location => {
      this.activeLocation = location;
      if (!this.purchaseId) {
        this.newPurchase();
      }
    });

  }

  loadPurchase() {
    console.log('load purchase: '+this.purchaseId);

    return new Promise((resolve, reject) => {
      this.purchase = null;
      this.ioService.post('/ise/loadPurchase', {
        purchaseId: this.purchaseId
      }).then((purchaseResponse: any) => {
        console.log('purchaseResponse: ', purchaseResponse);

        let activeLocation = this.locationService.getActiveLocation();
        console.log('Active Location', activeLocation);


        // Set the active location to match the active purchase
        let purchaseLocation = this.locationService.getLocation(purchaseResponse.purchase.locationId);
        this.locationService.setActiveLocation(purchaseLocation);

        // Get Purcahse Short ID
        purchaseResponse.purchase.shortId = purchaseResponse.purchase._id.slice(-6).toUpperCase();

        // See if purchase should be locked
        let pendingPaidTotal = 0;
        if (purchaseResponse.purchase.transactions.length > 0) {
          purchaseResponse.purchase.locked = true;

          // Process purchase transactions
          for (let transaction of purchaseResponse.purchase.transactions) {
            if (transaction.status == 'pending' || transaction.status == 'captured' || transaction.status == 'settled') {
              pendingPaidTotal += transaction.attemptedAmount;
            }
          }
        }
        purchaseResponse.purchase.pendingPaidTotal = pendingPaidTotal;


        this.purchase = purchaseResponse.purchase;

        console.log(this.purchase);

        // If this purchase is not locked, load the inventory options
        if (!this.purchase.locked) {
          this.loadInventory();
        } else {
          this.loadPurchases();
        }

        resolve();
      }).catch((error: any) => {
        console.log('loadPurchase ERROR: ', error);
        reject();
      });
    });

  }

  addPurchaseSync() {
    let condensedPurchase;
    let locationId;
    if (this.purchase) {
      condensedPurchase = this.getCondensedPurchase();
      locationId = this.purchase.locationId;
    } else {
      console.log('Creating a new purchase');
      if (this.activeLocation) {
        locationId = this.activeLocation._id;
      }
    }

    // If a locationId has been defined, trigger or queue the purchseSync
    if (locationId) {
      let purchaseSyncData = {
        locationId,
        purchase: condensedPurchase
      };

      // See if a sync is active
      if (this.purchaseSyncActive) {
        this.purchaseSyncQueue = purchaseSyncData;
      } else {
        this.purchaseSync(purchaseSyncData);
      }
    } // End if locaitonId is defined

  } // End addPurchaseSync


  purchaseSync(purchaseSyncData) {
    this.purchaseSyncActive = true;
    return new Promise((resolve, reject) => {
      console.log('purchaseServerSync:');
      this.ioService.post('/ise/purchaseSync', purchaseSyncData).then((syncResponse: any) => {
        console.log('syncResponse: ', syncResponse);

        // Allow
        this.purchaseSyncActive = false;

        // Response will either be updated, or updated and notify of other updates
        if (syncResponse.purchase) {
          // This is a new purchase request
          this.router.navigate(['purchase', syncResponse.purchase._id]);
        }

        // Handle Sync Queue
        if (this.purchaseSyncQueue) {
          this.purchaseSync(this.purchaseSyncQueue).then(() => {
            resolve();
          });
          this.purchaseSyncQueue = null;
        } else {
          resolve();
        }

      }).catch((syncError: any) => {
        console.log('syncError: ', syncError);
        this.purchase = null;
        reject();
      });
    });
  }


  newPurchase() {
    console.log('newPurchase');

    this.addPurchaseSync();
    this.purchase = null;

    this.addPurchaseSync();
  }


  getCondensedPurchase() {
    // Return only the fields the server needs
    let items = [];
    for (let item of this.purchase.items) {
      let condensedItem = {
        inventoryId: item.inventoryId,
        qty: item.qty,
        price: Number(item.price),
        notes: item.notes
      };

      items.push(condensedItem);
    }

    return {
      _id: this.purchase._id,
      customerId: this.purchase.customerId,
      items,
      notes: this.purchase.notes,
      tip: this.purchase.tip
    };
  }


  cancelPurchase() {
    console.log('cancelPurchase');

    this.ioService.post('/purchase/cancel', {
      purchaseId: this.purchase._id
    }).then((cancelResponse: any) => {
      this.purchase = null;
      this.addPurchaseSync();
    }).catch(err => {

    });
  }

  navigateToPurchase(purchaseId) {
    this.setLeftView(null);
    this.router.navigate(['purchase', purchaseId]);
  }





  addItem(item) {
    if (!this.purchase.locked) {
      let lineItem = {
        name: item.name,
        inventoryId: item._id,
        qty: 1,
        price: item.price,
        notes: [],
        priceIsVariable: item.priceIsVariable
      };

      this.purchase.items.push(lineItem);

      this.updateCalculations();

      this.setLeftView(null);
      setTimeout(() => {
        this.itemsScrollToBottom();
      }, 0);
    }
  }


  itemsScrollToBottom(): void {
    console.log('itemsScrollToBottom:');
    try {
      console.log('scrolling: ');
      this.itemsContainer.nativeElement.scrollTop = this.itemsContainer.nativeElement.scrollHeight+100;
    } catch(err) {}
  }


  startItemModify(lineItem) {
    this.activeLineItem = lineItem;
    this.setRightView('itemModify');
  }

  editItemPrice(lineItem) {
    // Set the activeLine Item
    this.activeLineItem = lineItem;
    // Set the visible/editable item price
    this.lineItemPrice = (lineItem.price/100).toFixed(2);
    // Open the price editor view
    this.setRightView('itemPrice');
    setTimeout(() => {
      // Set Focus on input
      this.itemPriceElement.nativeElement.focus();
    }, 400);
  }

  updateLineItemPrice() {
    this.activeLineItem.price = parseInt((this.lineItemPrice.replace(/[^\d.-]/g, '')*100).toFixed(0));
  }

  editItemNote(lineItem) {
    if (!this.purchase.locked) {
      // Set the activeLine Item
      this.activeLineItem = lineItem;

      // Make sure target note container is defined
      if (!this.activeLineItem.notes) {
        this.activeLineItem.notes = [];
      }
      if (this.activeLineItem.notes.length == 0) {
        this.activeLineItem.notes.push('');
      }

      // Open the item note editor
      this.setRightView('itemNote');

      setTimeout(() => {
        // Set Focus on input
        this.itemNotesElement.nativeElement.focus();
      }, 400);
    }
  }

  toggleItemModify(lineItem) {
    if (!this.purchase.locked) {
      lineItem.active = !lineItem.active;
    }
  }

  toggleCategoryExpanded(category) {
    category.expanded = !category.expanded;
  }





  closeSides() {
    this.setLeftView(null);
    this.setRightView(null);
  }


  setLeftView(view) {
    if (view == null) {
      this.leftOpen = false;
      this.tintActive = false;
      this.leftTimeout = setTimeout(() => {
        this.leftView = null;
        this.tintOn = false;
      }, 500);
      // this.addPurchaseSync();
    } else {
      console.log('Set Left View: '+view);
      this.leftView = view;
      this.tintOn = true;
      setTimeout(() => {
        this.tintActive = true;
      }, 0);
      clearTimeout(this.leftTimeout);
      this.leftOpen = true;
    }
  }

  setRightView(view) {
    if (view == null) {
      this.rightOpen = false;
      this.tintActive = false;
      this.rightTimeout = setTimeout(() => {
        this.rightView = null;
        this.tintOn = false;
      }, 500);
      this.updateCalculations();
    } else {
      console.log('Set Right View: '+view);
      this.rightView = view;
      this.tintOn = true;
      setTimeout(() => {
        this.tintActive = true;
      }, 0);
      clearTimeout(this.rightTimeout);
      this.rightOpen = true;
    }
  }




  updateCalculations(syncAfter = true) {
    if (!this.inventoryIndexed) {
      return false;
    }

    let subtotal = 0;
    let discount = 0;
    let taxes = 0;
    let due = 0;
    let taxDetails = {};
    let taxable = {};



    // Loop through Invoice-level taxes
    for (let tax of this.taxesInvoice) {
      taxable[tax._id] = 0;
      // Loop through items
      for (let item of this.purchase.items) {
        let itemConfig = this.getItemConfig(item.inventoryId);
        // See if this tax applies to this item
        if (itemConfig.taxes.indexOf(tax._id) != -1) {
          let lineSubtotal = item.price*item.qty;
          taxable[tax._id] += lineSubtotal;
        }
      }

      if (tax.rounding == 'roundUp') {
        taxDetails[tax._id] = Math.ceil((parseFloat(tax.percentage)/100*taxable[tax._id])-0.0000001);
      }

      if (tax.rounding == 'roundHalfUp') {
        taxDetails[tax._id] = Math.round((parseFloat(tax.percentage)/100*taxable[tax._id]));
      }

      taxes += taxDetails[tax._id];
    }




    for (let item of this.purchase.items) {
      console.log('item: ', item);
      let itemConfig = this.getItemConfig(item.inventoryId);
      console.log('itemConfig: ', itemConfig);

      let lineSubtotal = item.price*item.qty;
      subtotal += lineSubtotal;

      // Loop through Item-level taxes
      for (let tax of this.taxesItem) {
        console.log('item-level tax: ', tax);
        if (itemConfig.taxes.indexOf(tax._id) != -1) {

          if (!taxable[tax._id]) {
            taxable[tax._id] = 0;
            taxDetails[tax._id] = 0;
          }

          let taxAmount = 0;
          if (tax.rounding == 'roundUp') {
            console.log('round up');
            taxAmount = Math.ceil((parseFloat(tax.percentage)/100*lineSubtotal)-0.0000001);
            console.log('TAX AMOUNT: ', taxAmount);
          }

          if (tax.rounding == 'roundHalfUp') {
            console.log('round half up');
            taxAmount = Math.round((parseFloat(tax.percentage)/100*lineSubtotal));
          }

          console.log('tax: '+tax._id+' : '+lineSubtotal);

          taxable[tax._id] += lineSubtotal;
          taxDetails[tax._id] += taxAmount;
          taxes += taxAmount;
        }
      }

    } // End loop through purchase items




    // Update Totals
    this.purchase.subtotal = subtotal;
    this.purchase.discount = discount;
    this.purchase.taxes = taxes;
    this.purchase.taxable = taxable;
    this.purchase.taxDetails = taxDetails;
    this.purchase.total = subtotal + discount + this.purchase.tip + taxes;

    // Handle applied payments
    let paymentTotal = 0;
    if (this.purchase.transactions) {
      for (let transaction of this.purchase.transactions) {
        paymentTotal += transaction.amount;
      } // End loop through transactions
    } // End if there are transactions

    this.purchase.due = this.purchase.total + paymentTotal;

    console.log('updated purchase calculations: ', this.purchase);

    if (syncAfter) {
      this.addPurchaseSync();
    }
  }



  startItemAdd() {
    this.setLeftView('inventory');
  }

  // item-modify

  increaseQty(itemIndex) {
    this.purchase.items[itemIndex].qty++;
    this.updateCalculations();
  }

  decreaseQty(itemIndex) {
    if (this.purchase.items[itemIndex].qty > 1) {
      this.purchase.items[itemIndex].qty--;
      this.updateCalculations();
    }
  }

  removeItem(itemIndex) {
    this.purchase.items.splice(itemIndex, 1);
    this.updateCalculations();
  }















  // Inventory
  loadInventory() {
    this.inventory = null;
    this.inventoryIndexed = null;

    this.taxesItem = [];
    this.taxesInvoice = [];

    let locationId;
    if (this.purchase) {
      locationId = this.purchase.locationId;
    } else {
      if (this.activeLocation) {
        locationId = this.activeLocation._id;
      } else {

      }
    }

    this.ioService.post('/item/getItems', {
      locationId,
      getCategories: true,
      getTaxes: true
    }).then((inventoryResponse: any) => {
      this.inventory = inventoryResponse.items;
      this.inventoryIndexed = this.arrayToObject(inventoryResponse.items, '_id');

      this.taxes = this.arrayToObject(inventoryResponse.taxes, '_id');

      for (let tax of inventoryResponse.taxes) {

        if (!tax.rounding) {
          tax.rounding = 'roundUp';
        }

        if (!tax.basis) {
          tax.basis = 'item';
        }

        if (tax.basis == 'item') {
          this.taxesItem.push(tax);
        }

        if (tax.basis == 'invoice') {
          this.taxesInvoice.push(tax);
        }

      }

      this.updateCalculations(false);

      let categories = [];
      inventoryResponse.categories.sort();
      for (let categoryName of inventoryResponse.categories) {
        if (categoryName != null) {
          let category = {
            name: categoryName,
            expanded: false,
            items: []
          };

          for (let item of this.inventory) {
            if (item.categories.indexOf(categoryName) != -1) {
              category.items.push(item);
            }
          }

          categories.push(category);
        }
      }

      this.categories = categories;

      this.updatePurchaseItemsConfig();

    }).catch((error: any) => {

    });
  }




  loadPurchases() {
    this.purchases = null;
    let locationId;
    if (this.purchase) {
      locationId = this.purchase.locationId;
    } else {
      if (this.activeLocation) {
        locationId = this.activeLocation._id;
      } else {

      }
    }

    this.ioService.post('/ise/loadPurchases', {
      locationId,
      status: 'pending'
    }).then((purchasesResponse: any) => {
      this.purchases = purchasesResponse.purchases;
    }).catch((error: any) => {

    });
  }



  updatePurchaseItemsConfig() {
    if (this.purchase) {
      for (let item of this.purchase.items) {
        let itemConfig = this.getItemConfig(item.inventoryId);
        item.priceIsVariable = itemConfig.priceIsVariable;
      }
    }
  }


  getItemConfig(itemId) {
    console.log('getItemConfig: ', itemId);
    console.log('inventoryIndexed: ', this.inventoryIndexed);
    return this.inventoryIndexed[itemId];
  }


  getTaxConfig(taxId) {
    return this.taxes[taxId];
  }




  startNote() {
    console.log('startNote: ');
    this.setRightView('purchaseNote');
    setTimeout(() => {
      // Set Focus on input
      this.purchaseNotes.nativeElement.focus();
    }, 400);
  }

  startTip() {
    console.log('startTip: ');
    this.purchaseTip = (this.purchase.tip/100).toFixed(2);
    this.setRightView('purchaseTip');
    setTimeout(() => {
      // Set Focus on input
      this.purchaseTipElement.nativeElement.focus();
    }, 400);
  }

  updateTip() {
    this.purchase.tip = parseInt((this.purchaseTip.replace(/[^\d.-]/g, '')*100).toFixed(0));
  }

  setTipPercent(percent) {
    this.purchase.tip = parseInt((this.purchase.subtotal*percent).toFixed(0));
    this.purchaseTip = (this.purchase.tip/100).toFixed(2);
  }



  startCustomerSelect() {
    console.log('startCustomerSelect');
    this.setRightView('purchaseCustomer');
  }

  searchCustomers() {
    console.log('searchCustomers: ');
    console.log('customerSearchText: ', this.purchase.customerSearchText);
    if (this.purchase.customerSearchText && this.purchase.customerSearchText.length > 0) {
      // Search text is defined
      this.ioService.post('/ise/searchCustomers', {
        searchText: this.purchase.customerSearchText,
        locationId: this.purchase.locationId
      }).then((searchResponse: any) => {
        this.purchase.customerSearchResults = searchResponse.customers;
      }).catch((searchError: any) => {
        console.log('searchError: ', searchError);
        this.purchase.customerSearchResults = [];
      });
    } else {
      // Search text is blank
      console.log('customer search text is blank');
      this.purchase.customerSearchResults = null;
    }
  }

  setCustomer(customer) {
    if (customer) {
      this.purchase.customer = customer;
      this.purchase.customerId = customer._id;
    } else {
      this.purchase.customer = {};
      this.purchase.customerId = this.activeLocation.defaultCustomerId;
    }

    this.setRightView(null);
  }

  newCustomer() {
    this.purchase.newCustomer = {
      errors: {},
      valid: true
    };
    this.setRightView('newCustomer');
  }

  saveNewCustomer() {
    // Validate essential fields
    this.purchase.newCustomer.valid = true;
    this.purchase.newCustomer.errors = {};

    // Validate Phone Number
    if (this.purchase.newCustomer.telephone && this.purchase.newCustomer.telephone.length > 0) {
      if (this.purchase.newCustomer.telephone.length != 10) {
        // Telephone Validation Error
        this.purchase.newCustomer.errors.telephone = 'Invalid phone number.';
        this.purchase.newCustomer.valid = false;
      }
    }

    // Validate Email
    if (this.purchase.newCustomer.email && this.purchase.newCustomer.email.length > 0) {
      if (!this.emailIsValid(this.purchase.newCustomer.email)) {
        // Email Validation Error
        this.purchase.newCustomer.errors.email = 'Invalid email address.';
        this.purchase.newCustomer.valid = false;
      }
    }

    // Validate Something is defined
    if (!this.purchase.newCustomer.email && !this.purchase.newCustomer.telephone && !this.purchase.newCustomer.firstName && !this.purchase.newCustomer.lastName) {
      this.purchase.newCustomer.valid = false;
    }

    if (this.purchase.newCustomer.valid) {
      this.ioService.post('/ise/saveCustomer', {
        customer: this.purchase.newCustomer,
        locationId: this.purchase.locationId
      }).then((newCustomerResponse: any) => {
        this.setCustomer(newCustomerResponse.customer);
      });
    }


  }




  lockPurchase() {

  }

  unlockPurchase() {

  }



  numPress(num) {
    if (this.purchase[this.purchase.numPadFocus] == this.purchase.numPadDefault) {
      this.purchase[this.purchase.numPadFocus] = 0;
    }

    this.purchase[this.purchase.numPadFocus] = parseInt(String(this.purchase[this.purchase.numPadFocus])+num);
  }

  deletePress() {
    if (String(this.purchase[this.purchase.numPadFocus]).length > 1) {
      this.purchase[this.purchase.numPadFocus] = parseInt(String(this.purchase[this.purchase.numPadFocus]).slice(0, -1));
    } else {
      this.purchase[this.purchase.numPadFocus] = 0;
    }

    if (this.purchase[this.purchase.numPadFocus] == 0) {
      this.purchase[this.purchase.numPadFocus] = this.purchase.numPadDefault;
    }
  }




  startDiscount() {
    console.log('startDiscount: ');
    this.setRightView('purchaseDiscount');
  }



  cancelPayment() {
    console.log('cancelPayment: ');
    this.purchase.paying = null;
    this.purchase.selectingPayment = null;
  }

  startPay() {
    console.log('startPay: ');
    this.purchase.selectingPayment = true;
    this.purchase.paymentAmount = this.purchase.due;
  }

  startPayCredit() {
    console.log('startPayCredit: ');

    this.purchase.paymentAmount = this.purchase.due;

    this.purchase.billing = {
      firstName: null,
      lastName: null,
      streetAddress: null,
      addressLocality: null,
      addressRegion: null,
      postalCode: null
    };

    if (this.purchase.customer && this.purchase.customer.address) {
      this.purchase.billing = this.purchase.customer.address;
    }

    console.log('PURCHASE: ', this.purchase);

    this.requestOosKey();
    this.purchase.paying = 'credit';

  }


  getOosUrl() {
    let iframeUrl = environment.oosUrl+'form?clientId='+environment.clientId+'&locationId='+this.purchase.locationId+'&key='+this.purchase.oosKey;

    // Add style parameters to fuse request
    iframeUrl += '&backgroundColor=231f20';
    iframeUrl += '&color=ffffff';

    return this.sanitizer.bypassSecurityTrustResourceUrl(iframeUrl);
  }

  requestOosKey() {
    console.log('requestOosKey: ');
    this.purchase.oosKey = null;
    if (this.purchase.locationId) {
      this.ioService.post('/transaction/credit/check', {
        locationId: this.purchase.locationId,
      }).then((result: any) => {
        this.purchase.oosKey = result.key;
        this.purchase.oosUrl = this.getOosUrl();
      }).catch((err: any) => {
        console.log(err);
      });
    }
  }

  validateCreditCard() {

    if (this.purchase.tokenized && this.purchase.csc && this.purchase.csc.length > 2) {
      this.purchase.creditCardValid = true;
    } else {
      this.purchase.creditCardValid = false;
    }



  }

  payCredit() {
    if (this.purchase.creditTransStatus == null) {
      this.purchase.creditTransStatus = 'processing';

      // if (!this.purchase.billing.postalCode || this.purchase.billing.postalCode.length < 5) {
      //
      // }

      let postData = {
        locationId: this.purchase.locationId,
        purchaseId: this.purchase._id,
        customerId: this.purchase.customerId,
        amount: (parseInt(this.purchase.paymentAmount)/100).toFixed(2),
        payment: {
          key: this.purchase.oosKey,
          csc: this.purchase.csc
        },
        billing: this.purchase.billing,
        context: {
          softwareType: 'Red Fire IR ',
          softwareTypeVersion: environment.version
        }
      };

      this.ioService.post('/transaction/credit/subtract', postData).then((creditSubtractResponse: any) => {
        this.purchase.creditTransResponse = creditSubtractResponse;
        this.purchase.creditTransStatus = 'success';
        setTimeout(() => {
          this.loadPurchase();
        }, 3000);
      }).catch((transactionError: any) => {
        this.purchase.creditTransResponse = transactionError.error;
        this.purchase.creditTransStatus = 'error';
        setTimeout(() => {
          this.loadPurchase();
        }, 3000);
      });

    }
  }










  startPayCash() {
    console.log('startPayCash: ');

    this.purchase.paymentAmount = this.purchase.due;

    this.purchase.numPadFocus = 'amountTendered';
    this.purchase.numPadDefault = this.purchase.due;
    this.purchase.amountTendered = this.purchase.due;
    this.purchase.change = null;
    this.purchase.paying = 'cash';
  }

  addAmountTendered(amount) {
    if (this.purchase.amountTendered == this.purchase.due) {
      this.purchase.amountTendered = 0;
    }

    this.purchase.amountTendered += amount;
  }

  calculateChange() {

  }

  payCash() {
    console.log('payCash: ');
    console.log(this.purchase);


    if (this.purchase.amountTendered <= this.purchase.paymentAmount || this.purchase.change) {
      // Add Cash Transaction

      // Lock purchase
      this.purchase.locked = true;

      let postData = {
        locationId: this.purchase.locationId,
        purchaseId: this.purchase._id,
        customerId: this.purchase.customerId,
        amount: (parseInt(this.purchase.paymentAmount)/100).toFixed(2),
      };

      if (parseInt(this.purchase.amountTendered) < parseInt(this.purchase.paymentAmount)) {
        postData.amount = (parseInt(this.purchase.amountTendered)/100).toFixed(2)
      }

      this.ioService.post('/transaction/cash/subtract', postData).then((cashSubtractResponse: any) => {
        this.loadPurchase();
      }).catch((transactionError: any) => {
        console.log('transactionError: ', transactionError);
      });
    } else {
      // Show Change
      this.purchase.change = this.purchase.amountTendered - this.purchase.paymentAmount;
      // this.purchase.amountTendered = this.purchase.paymentAmount;
    }
  }

  startPayInvoice() {
    console.log('startPayInvoice: ');

    this.purchase.paymentAmount = this.purchase.due;

    this.purchase.invoiceEmail = this.purchase.customer.email;
    this.purchase.paying = 'invoice';
    this.validateInvoiceEmail();
  }

  validateInvoiceEmail() {
    this.purchase.invoiceEmailValid = this.emailIsValid(this.purchase.invoiceEmail);
  }

  emailIsValid(email) {
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
  }

  sendInvoice() {
    console.log('sendInvoice: ');

    // Lock purchase
    this.purchase.locked = true;

    if (!this.purchase.invoiceNotes) {
      this.purchase.invoiceNotes = '';
    }

    let postData = {
      locationId: this.purchase.locationId,
      transactionId: null,
      purchaseId: this.purchase._id,
      // customerId: this.purchaseService.getCustomerId(),
      amount: (parseInt(this.purchase.paymentAmount)/100).toFixed(2),
      email: this.purchase.invoiceEmail,
      description: this.purchase.invoiceNotes
    };

    this.ioService.post('/transaction/invoice/add', postData).then((invoiceAddResponse: any) => {
      this.loadPurchase();
    });
  }


  sendReceipt() {
    if (this.emailIsValid(this.purchase.customer.email)) {

      if (this.purchase.receiptStatus == null) {
        this.purchase.receiptStatus = 'Sending . . .';

        this.ioService.post('/ise/emailPurchaseReceipt', {
          locationId: this.purchase.locationId,
          purchaseId: this.purchase._id,
          email: this.purchase.customer.email
        }).then((sendReceiptResponse: any) => {
          this.purchase.receiptStatus = 'Sent to '+this.purchase.customer.email;
          // this.loadPurchase();
        });
      }

    } else {
      console.log('INVALID EMAIL ADDRESS: ', this.purchase.customer.email);
    }
  }





}
