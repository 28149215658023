import { Component, OnInit, OnDestroy } from '@angular/core';
import { Location } from '@angular/common';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { Observable, Subject, Subscription, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';

import { HistoryService } from '../../../services/history/history.service';
import { IoService } from '../../../services/io/io.service';
import { LocationService } from '../../../services/location/location.service';
import { AuthorizationService } from '../../../services/authorization/authorization.service';
import { MessageService } from '../../../services/message/message.service';

@Component({
  selector: 'app-detail-customer',
  templateUrl: './detail-customer.component.html',
  styleUrls: ['./detail-customer.component.scss']
})
export class DetailCustomerComponent implements OnInit {

  documentId$: Observable<any>;
  documentId;

  showBackButton;

  customer;
  agreements;

  savingCustomer;
  error;

  authorization;

  addingLoyalty;
  subtractingLoyalty;

  loyaltyAddAmount;
  loyaltySubtractAmount;

  loyaltyAddRequestAmount;
  loyaltySubtractRequestAmount;

  loyaltyTransacting;

  private permissionsLoaded: Subscription;
  private activeLocationSet: Subscription;


  canCommunicate;
  mobilephoneConfirmed;
  conversationActive;
  conversationActiveUpdated: Subscription;

  constructor(
    private location: Location,
    private route: ActivatedRoute,
    private router: Router,
    private historyService: HistoryService,
    private ioService: IoService,
    private locationService: LocationService,
    private authorizationService: AuthorizationService,
    private messageService: MessageService
  ) { }

  ngOnInit() {
    this.documentId$ = this.route.paramMap.pipe(switchMap(params => of(params.get('documentId'))));
    this.documentId$.subscribe(documentId => {
      if (documentId) {
        this.documentId = documentId;

        if (this.authorizationService.permissionsAreLoaded()) {
          this.afterPermissionsLoaded();
        } else {
          this.permissionsLoaded = this.authorizationService.permissionsLoadedObservable().subscribe(() => {
            this.afterPermissionsLoaded();
          });
        }

        // If there is already an active conversation, close it unless it is this customer
        if (this.conversationActive && this.conversationActive != this.documentId) {
          this.messageService.closeConversation();
        }

      }
    });

    this.activeLocationSet = this.locationService.activeLocationSetObservable().subscribe(locationSet => {
      this.checkPermissions();
    });

    if (this.historyService.getPreviousLinkDepth() > 3) {
      this.showBackButton = true;
    }

    this.conversationActiveUpdated = this.messageService.conversationActiveUpdatedObservable().subscribe(conversationActive => {
      this.conversationActive = conversationActive;
    });
    this.conversationActive = this.messageService.getConversationActive();

    // If there is already an active conversation, close it unless it is this customer
    if (this.conversationActive && this.conversationActive != this.documentId) {
      this.messageService.closeConversation();
    }

  }

  ngOnDestroy() {
    if (this.permissionsLoaded) {
      this.permissionsLoaded.unsubscribe();
    }

    if (this.activeLocationSet) {
      this.activeLocationSet.unsubscribe();
    }

    if (this.conversationActiveUpdated) {
      this.conversationActiveUpdated.unsubscribe();
    }
  }

  afterPermissionsLoaded() {
    // See if active user can communicate
    if (this.authorizationService.checkPermission('location.communicate', 'any')) {
      this.canCommunicate = true;
    }
    this.loadCustomerDetail();
  }

  close() {
    this.router.navigate(['../../'], { relativeTo: this.route });
  }

  back() {
    this.location.back();
  }

  loadDetail(type, id) {
    this.router.navigate(['../../'+type+'/'+id], { relativeTo: this.route });
  }

  loadCustomerDetail() {
    console.log('loadCustomerDetail: ');
    this.error = null;
    this.mobilephoneConfirmed = null;
    this.customer = null;
    this.agreements = null;

    this.addingLoyalty = null;
    this.subtractingLoyalty = null;
    this.loyaltyAddAmount = null;
    this.loyaltySubtractAmount = null;
    this.loyaltyAddRequestAmount = null;
    this.loyaltySubtractRequestAmount = null;
    this.loyaltyTransacting = null;


    this.ioService.post('/customer/getDetails', {
      customerId: this.documentId
    }).then((customerResponse: any) => {
      console.log('customerResponse: ');
      console.log(customerResponse);

      let purchaseTotal = 0;
      for (let purchase of customerResponse.customer.purchases) {
        if (purchase.status == 'complete') {
          purchaseTotal += purchase.total;
        }
      }
      customerResponse.customer.purchaseTotal = purchaseTotal;

      if (!customerResponse.customer.birthday) {
        customerResponse.customer.birthday = {
          month: '',
          day: ''
        };
      } else {
        if (customerResponse.customer.birthday.day && customerResponse.customer.birthday.day.slice(0, 1) == '0') {
          customerResponse.customer.birthday.day = customerResponse.customer.birthday.day.slice(1);
        }
      }

      this.customer = customerResponse.customer;
      if (customerResponse.customer.mobilephone && customerResponse.customer.mobilephone.length == 10) {
        this.mobilephoneConfirmed = true;
      }

      if (this.customer.agreements) {
        this.agreements = [];
        for (let agreementId in this.customer.agreements) {
          this.agreements.push(this.customer.agreements[agreementId]);
        }
      }

      this.checkPermissions();
    });
  }

  saveChanges() {
    console.log('saveChanges');
    this.savingCustomer = true;
    this.error = null;

    // Send only editable customer data
    this.ioService.post('/customer/saveChanges', {
      customer: {
        _id: this.customer._id,
        telephone: this.customer.telephone,
        mobilephone: this.customer.mobilephone,
        firstName: this.customer.firstName,
        middleName: this.customer.middleName,
        lastName: this.customer.lastName,
        email: this.customer.email,
        birthday: this.customer.birthday,
        address: this.customer.address
      }
    }).then((saveResponse: any) => {
      console.log('saved customer');
      this.savingCustomer = false;
    }).catch((saveError: any) => {
      this.savingCustomer = false;
      console.log('save error: ', saveError);
      this.error = saveError.error.msg;
    });
  }

  checkPermissions() {
    this.authorization = {
      agreementCheck: false,
      agreementAdd: false,
      agreementSubtract: false,
      agreementAny: false,
      loyaltyCheck: false,
      loyaltyAdd: false,
      loyaltySubtract: false,
      mergeCustomers: false
    };

    // Get Permission Checks
    let activeLocation = this.locationService.getActiveLocation();
    // console.log('ACTIVE LOCATION: ', activeLocation);
    this.authorization.agreementCheck = this.authorizationService.checkPermission('location.ledger.agreement.check', 'any');



    this.authorization.loyaltyCheck = this.authorizationService.checkPermission('location.ledger.loyalty.check', 'any');
    if (activeLocation && activeLocation._id) {
      this.authorization.loyaltyAdd = this.authorizationService.checkPermission('location.ledger.loyalty.add', activeLocation._id);
      this.authorization.loyaltySubtract = this.authorizationService.checkPermission('location.ledger.loyalty.subtract', activeLocation._id);

      this.authorization.agreementAdd = this.authorizationService.checkPermission('location.ledger.agreement.add', activeLocation._id);
      this.authorization.agreementSubtract = this.authorizationService.checkPermission('location.ledger.agreement.subtract', activeLocation._id);
      this.authorization.agreementAny = (this.authorization.agreementAdd || this.authorization.agreementSubtract);

      // this.authorization.mergeCustomers = this.authorizationService.checkPermission('location.mergeCustomers', activeLocation._id);
      // if (this.authorization.mergeCustomers) {
      //   this.ioService.post('/customer/findCustomerDuplicates', {
      //     customerId: this.documentId
      //   }).then((findResponse: any) => {
      //     this.customer.potentialDuplicates = findResponse.duplicates;
      //   }).catch((findError: any) => {
      //     alert('findCustomerDuplicates error');
      //   });
      // }


    }
    // console.log('AUTHORIZATION: ', this.authorization);
  }

  startSubtractLoyaltyPoints() {
    this.subtractingLoyalty = true;
  }

  verifyLoyaltySubtractAmount() {
    if (this.loyaltySubtractAmount < 0) {
      this.loyaltySubtractAmount = this.loyaltySubtractAmount*-1;
    }
    if (this.loyaltySubtractAmount > this.customer.loyaltyBalance/100) {
      this.loyaltySubtractAmount = this.customer.loyaltyBalance/100;
    }
    if (this.loyaltySubtractAmount > (68*5)) {
      this.loyaltySubtractAmount = (68*5);
    }
  }

  loyaltySubtractVerify() {
    console.log('loyaltySubtractVerify:');
    this.verifyLoyaltySubtractAmount();
    this.loyaltySubtractRequestAmount = Number(this.loyaltySubtractAmount).toFixed(0);
  }

  stopSubtractLoyaltyPoints() {
    this.subtractingLoyalty = false;
  }

  loyaltySubtractCancel() {
    this.loyaltySubtractRequestAmount = null;
  }

  loyaltySubtract() {
    console.log('loyaltySubtract:');
    if (!this.loyaltyTransacting) {
      this.loyaltyTransacting = true;
      this.ioService.post('/transaction/loyalty/subtract', {
        locationId: this.locationService.getActiveLocation()._id,
        amount: this.loyaltySubtractRequestAmount,
        customerId: this.documentId
      }).then((subtractResponse: any) => {
        console.log('subtractResponse: ', subtractResponse);
        this.loadCustomerDetail();
      });
    }
  }


  startAddLoyaltyPoints() {
    this.addingLoyalty = true;
  }

  verifyLoyaltyAddAmount() {
    if (this.loyaltyAddAmount < 0) {
      this.loyaltyAddAmount = this.loyaltyAddAmount*-1;
    }
    if (this.loyaltyAddAmount > 68) {
      this.loyaltyAddAmount = 68;
    }
  }

  loyaltyAddVerify() {
    console.log('loyaltyAddVerify:');
    this.verifyLoyaltyAddAmount();
    this.loyaltyAddRequestAmount = Number(this.loyaltyAddAmount).toFixed(0);
  }

  stopAddLoyaltyPoints() {
    this.addingLoyalty = false;
  }

  loyaltyAddCancel() {
    this.loyaltyAddRequestAmount = null;
  }

  loyaltyAdd() {
    console.log('loyaltyAdd:');
    if (!this.loyaltyTransacting) {
      this.loyaltyTransacting = true;
      this.ioService.post('/transaction/loyalty/add', {
        locationId: this.locationService.getActiveLocation()._id,
        amount: this.loyaltyAddRequestAmount,
        customerId: this.documentId
      }).then((addResponse: any) => {
        console.log('addResponse: ', addResponse);
        this.loadCustomerDetail();
      });
    }
  }

  loadConversation() {
    this.messageService.loadConversation(this.customer._id);
  }

  toggleAgreementVt(agreement) {
    agreement.vtActive = !agreement.vtActive;
  }

  agreementTrans(agreement, transType) {
    this.ioService.post('/transaction/agreement/'+transType, {
      locationId: this.locationService.getActiveLocation()._id,
      amount: '0.01',
      customerId: this.documentId,
      agreementId: agreement._id
    }).then((agreeTransResponse: any) => {
      console.log('agreeTransResponse: ', agreeTransResponse);
      this.loadCustomerDetail();
    });
  }

  mergeCustomers(sourceCustomer) {
    this.ioService.post('/customer/mergeCustomers', {
      targetCustomerId: this.documentId,
      sourceCustomerId: sourceCustomer._id
    }).then((mergeResponse: any) => {
      this.loadCustomerDetail();
    });
  }

}
