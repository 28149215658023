import { Component, OnInit, OnDestroy } from '@angular/core';
import { Location } from '@angular/common';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { Observable, Subject, Subscription, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';

import { HistoryService } from '../../../services/history/history.service';
import { IoService } from '../../../services/io/io.service';
import { AuthorizationService } from '../../../services/authorization/authorization.service';
import { MessageService } from '../../../services/message/message.service';

@Component({
  selector: 'app-detail-purchase',
  templateUrl: './detail-purchase.component.html',
  styleUrls: ['./detail-purchase.component.scss']
})
export class DetailPurchaseComponent implements OnInit {

  documentId$: Observable<any>;
  documentId;

  showBackButton;

  purchase;

  conversationActive;
  conversationActiveUpdated: Subscription;

  permissionsLoaded: Subscription;
  permissions;

  constructor(
    private location: Location,
    private route: ActivatedRoute,
    private router: Router,
    private historyService: HistoryService,
    private ioService: IoService,
    private authorizationService: AuthorizationService,
    private messageService: MessageService
  ) { }

  ngOnInit() {
    this.documentId$ = this.route.paramMap.pipe(switchMap(params => of(params.get('documentId'))));
    this.documentId$.subscribe(documentId => {
      if (documentId) {
        this.documentId = documentId;
        this.loadPurchaseDetail();
      }
    });

    if (this.historyService.getPreviousLinkDepth() > 3) {
      this.showBackButton = true;
    }

    this.conversationActiveUpdated = this.messageService.conversationActiveUpdatedObservable().subscribe(conversationActive => {
      this.conversationActive = conversationActive;
    });
    this.conversationActive = this.messageService.getConversationActive();
  }

  ngOnDestroy() {
    if (this.conversationActiveUpdated) {
      this.conversationActiveUpdated.unsubscribe();
    }
    if (this.permissionsLoaded) {
      this.permissionsLoaded.unsubscribe();
    }
  }

  close() {
    this.router.navigate(['../../'], { relativeTo: this.route });
  }

  back() {
    this.location.back();
  }

  monitorPermissions() {
    if (this.authorizationService.permissionsAreLoaded()) {
      this.checkPermissions();
    } else {
      this.permissionsLoaded = this.authorizationService.permissionsLoadedObservable().subscribe(() => {
        this.checkPermissions();
      });
    }
  }

  checkPermissions() {
    this.permissions.canCancel = this.authorizationService.checkPermission('location.purchase.cancel', this.purchase.locationId);
    this.permissions.canReturn = this.authorizationService.checkPermission('location.purchase.return', this.purchase.locationId);
    this.permissions.canEdit = this.authorizationService.checkPermission('location.purchaseView', this.purchase.locationId);
  }

  loadDetail(type, id) {
    this.router.navigate(['../../'+type+'/'+id], { relativeTo: this.route });
  }

  loadPurchaseDetail() {
    return new Promise((resolve, reject) => {
      if (this.permissionsLoaded) {
        this.permissionsLoaded.unsubscribe();
      }
      this.permissions = {
        canCancel: false,
        canReturn: false,
        canEdit: false
      };
      this.purchase = null;
      this.ioService.post('/purchase/getDetail', {
        purchaseId: this.documentId
      }).then((purchaseResponse: any) => {

        let transactions = [];
        let addValues = [];
        let pendingTransactions = false;
        for (let transaction of purchaseResponse.purchase.transactions) {
          if (transaction.type == 'gift' || transaction.type == 'Gift') {
            transaction.cardType = 'Gift Card';
          }

          if (transaction.type == 'cash') {
            transaction.cardType = 'Cash';
          }

          if (transaction.type == 'invoice') {
            transaction.cardType = 'E-Invoice';
          }

          if (transaction.type == 'reward') {
            transaction.cardType = 'Reward';
          }

          if (transaction.type == 'credit') {
            if (!transaction.cardNumber) {
              transaction.omit = true;
            }
          }

          if (transaction.status == 'pending') {
            pendingTransactions = true;
          }

          if (transaction.amount <= 0 && !transaction.omit) {
            transactions.push(transaction);
          } else {
            if (transaction.type == 'gift' || transaction.type == 'Gift') {
              addValues.push(transaction);
            }
          }
        }
        purchaseResponse.purchase.pendingTransactions = pendingTransactions;
        purchaseResponse.purchase.transactions = transactions;
        purchaseResponse.purchase.addValues = addValues;

        if (!purchaseResponse.purchase.due) {
          purchaseResponse.purchase.due = 0;
        }

        // Temporary rename for old gift card name
        if (purchaseResponse.purchase.shippingAddress) {
          purchaseResponse.purchase.shipping = purchaseResponse.purchase.shippingAddress;
        }

        this.purchase = purchaseResponse.purchase;
        console.log(this.purchase);

        this.monitorPermissions();
      });
    });
  }

  cancelPurchase() {
    console.log('cancel purchase');
    this.ioService.post('/purchase/cancel', {
      purchaseId: this.documentId
    }).then((cancelResponse: any) => {
      this.loadPurchaseDetail();
    }).catch(err => {

    });
  }

}
