import { Component, ViewChild, ElementRef, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';

import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { LocationService } from '../../../services/location/location.service';
import { MessageService } from '../../../services/message/message.service';
// import { IoService } from '../../../services/io/io.service';

@Component({
  selector: 'app-conversation',
  templateUrl: './conversation.component.html',
  styleUrls: ['./conversation.component.scss']
})
export class ConversationComponent implements OnInit {

  conversation;
  messageBody;
  messagesUpdated: Subscription;

  @ViewChild('messages', { static: false }) messagesElement: ElementRef;
  @ViewChild('message', { static: false }) messageElement: ElementRef;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private locationService: LocationService,
    private messageService: MessageService
  ) { }

  ngOnInit() {
    this.conversation = this.messageService.getConversation();

    if (!this.conversation.customer.firstName && !this.conversation.customer.lastName) {
      this.conversation.customer.firstName = 'New';
      this.conversation.customer.lastName = 'Customer';
    }

    // Subscribe to new messages
    this.messagesUpdated = this.messageService.messagesUpdatedObservable().subscribe(messages => {
      console.log('conversation messages updated: ', messages);
      this.conversation.messages = messages;
      this.scrollToBottom();
    });

    this.scrollToBottom();
  }

  ngOnDestroy() {
    if (this.messagesUpdated) {
      this.messagesUpdated.unsubscribe();
    }
  }

  close() {
    console.log('close conversation');
    this.messageService.closeConversation();
  }

  scrollToBottom() {
    setTimeout(() => {
      this.messagesElement.nativeElement.scrollTop = this.messagesElement.nativeElement.scrollHeight;
    }, 0);
  }

  focusOnMessage() {
    setTimeout(() => {
      this.messageElement.nativeElement.focus();
    }, 0);
  }

  messageKeydown(event) {
    if (event.key === "Enter") {
      event.preventDefault();
      console.log(event);
      this.send();
    }
  }

  send() {
    if (this.messageBody && this.messageBody != '') {

      let sendingLocationId;
      if (this.conversation.messages && this.conversation.messages.length > 0) {
        sendingLocationId = this.conversation.messages[this.conversation.messages.length-1].locationId;
      } else {
        sendingLocationId = this.locationService.getActiveLocation()._id
      }

      this.messageService.sendMessage({
        customerId: this.conversation.customer._id,
        locationId: sendingLocationId,
        body: this.messageBody
      });

      this.messageBody = null;
      this.focusOnMessage();
    }
  }

  showCustomer() {
    console.log(this.conversation);
    console.log('show customer details');
    console.log(this.router.url);
    let pathArray = this.router.url.split("/");
    console.log(pathArray);
    this.router.navigate([pathArray[1], pathArray[2], 'customer', this.conversation.customer._id]);
  }

}
