import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

// Import Guards
import { AuthGuard } from './guards/auth/auth.guard';

// Import View Components
import { SignInComponent } from './modules/authentication/sign-in/sign-in.component';
import { ResetPasswordComponent } from './modules/authentication/reset-password/reset-password.component';
import { SetPasswordComponent } from './modules/authentication/set-password/set-password.component';
import { ReportComponent } from './modules/report/report.component';
import { ReportDataComponent } from './modules/report/report-data/report-data.component';

import { MarketingComponent } from './modules/marketing/marketing.component';
import { DashboardComponent } from './modules/marketing/dashboard/dashboard.component';
import { CommunicationComponent } from './modules/marketing/communication/communication.component';
import { ScheduledComponent } from './modules/marketing/scheduled/scheduled.component';
import { RecentComponent } from './modules/marketing/recent/recent.component';

import { VirtualTerminalComponent } from './modules/virtual-terminal/virtual-terminal.component';

import { PurchaseComponent } from './modules/purchase/purchase.component';
import { FulfillmentComponent } from './modules/fulfillment/fulfillment.component';
import { AdminComponent } from './modules/admin/admin.component';

import { DetailAccountComponent } from './modules/report/detail-account/detail-account.component';
import { DetailBatchComponent } from './modules/report/detail-batch/detail-batch.component';
import { DetailCustomerComponent } from './modules/report/detail-customer/detail-customer.component';
import { DetailDiscountComponent } from './modules/report/detail-discount/detail-discount.component';
import { DetailDynamicComponent } from './modules/report/detail-dynamic/detail-dynamic.component';
import { DetailInventoryComponent } from './modules/report/detail-inventory/detail-inventory.component';
import { DetailLocationComponent } from './modules/report/detail-location/detail-location.component';
import { DetailPurchaseComponent } from './modules/report/detail-purchase/detail-purchase.component';
import { DetailReportComponent } from './modules/report/detail-report/detail-report.component';
import { DetailTransactionComponent } from './modules/report/detail-transaction/detail-transaction.component';
import { DetailUserComponent } from './modules/report/detail-user/detail-user.component';
import { DetailFormComponent } from './modules/report/detail-form/detail-form.component';
import { DetailTaxComponent } from './modules/report/detail-tax/detail-tax.component';
import { DetailPermissionComponent } from './modules/report/detail-permission/detail-permission.component';
import { DetailGroupComponent } from './modules/report/detail-group/detail-group.component';
import { DetailEmployeeComponent } from './modules/report/detail-employee/detail-employee.component';

import { ConversationsComponent } from './modules/report/conversations/conversations.component';
import { SettingsComponent } from './modules/marketing/settings/settings.component';
import { BirthdayComponent } from './modules/marketing/birthday/birthday.component';
import { AnniversaryComponent } from './modules/marketing/anniversary/anniversary.component';
import { AutoresponderComponent } from './modules/marketing/autoresponder/autoresponder.component';


const routes: Routes = [
  {
    path: 'sign-in',
    component: SignInComponent
  },
  {
    path: 'reset-password',
    component: ResetPasswordComponent
  },
  {
    path: 'set-password',
    component: SetPasswordComponent
  },
  {
    path: 'reports',
    component: ReportComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: ':alias',
        component: ReportDataComponent,
        children: [
          {
            path: 'conversations',
            component: ConversationsComponent
          },
          {
            path: 'account/:documentId',
            component: DetailAccountComponent
          },
          {
            path: 'batch/:documentId',
            component: DetailBatchComponent
          },
          {
            path: 'customer/:documentId',
            component: DetailCustomerComponent
          },
          {
            path: 'discount/:documentId',
            component: DetailDiscountComponent
          },
          {
            path: 'dynamic/:documentId',
            component: DetailDynamicComponent
          },
          {
            path: 'inventory/:documentId',
            component: DetailInventoryComponent
          },
          {
            path: 'location/:documentId',
            component: DetailLocationComponent
          },
          {
            path: 'purchase/:documentId',
            component: DetailPurchaseComponent
          },
          {
            path: 'report/:documentId',
            component: DetailReportComponent
          },
          {
            path: 'transaction/:documentId',
            component: DetailTransactionComponent
          },
          {
            path: 'user/:documentId',
            component: DetailUserComponent
          },
          {
            path: 'employee/:documentId',
            component: DetailEmployeeComponent
          },
          {
            path: 'form/:documentId',
            component: DetailFormComponent
          },
          {
            path: 'tax/:documentId',
            component: DetailTaxComponent
          },
          {
            path: 'permission/:documentId',
            component: DetailPermissionComponent
          },
          {
            path: 'group/:documentId',
            component: DetailGroupComponent
          }
        ]
      }
    ]
  },
  {
    path: 'marketing',
    component: MarketingComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: 'dashboard',
        component: DashboardComponent,
        children: [
          {
            path: 'conversations',
            component: ConversationsComponent
          },
          {
            path: 'account/:documentId',
            component: DetailAccountComponent
          },
          {
            path: 'batch/:documentId',
            component: DetailBatchComponent
          },
          {
            path: 'customer/:documentId',
            component: DetailCustomerComponent
          },
          {
            path: 'discount/:documentId',
            component: DetailDiscountComponent
          },
          {
            path: 'dynamic/:documentId',
            component: DetailDynamicComponent
          },
          {
            path: 'inventory/:documentId',
            component: DetailInventoryComponent
          },
          {
            path: 'location/:documentId',
            component: DetailLocationComponent
          },
          {
            path: 'purchase/:documentId',
            component: DetailPurchaseComponent
          },
          {
            path: 'report/:documentId',
            component: DetailReportComponent
          },
          {
            path: 'transaction/:documentId',
            component: DetailTransactionComponent
          },
          {
            path: 'user/:documentId',
            component: DetailUserComponent
          }
        ]
      },
      // {
      //   path: 'scheduled',
      //   component: ScheduledComponent
      // },
      // {
      //   path: 'recent',
      //   component: RecentComponent
      // },
      {
        path: 'general',
        children: [
          {
            path: 'autoresponder',
            component: AutoresponderComponent
          },
          {
            path: 'settings',
            component: SettingsComponent
          }
        ]
      },
      {
        path: 'automatic',
        children: [
          {
            path: 'birthday',
            component: BirthdayComponent
          },
          {
            path: 'anniversary',
            component: AnniversaryComponent
          }
        ]
      },
      {
        path: 'communication/:documentId',
        component: CommunicationComponent,
        children: [
          {
            path: 'conversations',
            component: ConversationsComponent
          },
          {
            path: 'account/:documentId',
            component: DetailAccountComponent
          },
          {
            path: 'batch/:documentId',
            component: DetailBatchComponent
          },
          {
            path: 'customer/:documentId',
            component: DetailCustomerComponent
          },
          {
            path: 'discount/:documentId',
            component: DetailDiscountComponent
          },
          {
            path: 'dynamic/:documentId',
            component: DetailDynamicComponent
          },
          {
            path: 'inventory/:documentId',
            component: DetailInventoryComponent
          },
          {
            path: 'location/:documentId',
            component: DetailLocationComponent
          },
          {
            path: 'purchase/:documentId',
            component: DetailPurchaseComponent
          },
          {
            path: 'report/:documentId',
            component: DetailReportComponent
          },
          {
            path: 'transaction/:documentId',
            component: DetailTransactionComponent
          },
          {
            path: 'user/:documentId',
            component: DetailUserComponent
          }
        ]
      },
      {
        path: '',
        redirectTo: 'dashboard',
        pathMatch: 'prefix'
      }
    ]
  },
  {
    path: 'virtual-terminal',
    component: VirtualTerminalComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: 'conversations',
        component: ConversationsComponent
      }
    ]
  },
  {
    path: 'purchase/:purchaseId',
    component: PurchaseComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'purchase',
    component: PurchaseComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'fulfillment',
    component: FulfillmentComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'admin',
    component: AdminComponent,
    canActivate: [AuthGuard]
  },
  {
    path: '',
    redirectTo: 'reports',
    pathMatch: 'full'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
