import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { Router, ActivatedRoute } from '@angular/router';

import * as moment from 'moment';

import { IoService } from '../io/io.service';
import { ScreenService } from '../screen/screen.service';
import { LocationService } from '../location/location.service';

@Injectable({
  providedIn: 'root'
})
export class ReportService {

  private reportCategories;
  private reportsByAlias = {};
  private pendingReportAlias;

  private defaultReport;
  private activeReportConfig;

  private dateRange: {
    startDate: moment.Moment,
    endDate: moment.Moment
  };

  private sorts;
  private indexedSorts;
  private activeFields;
  private search: string;
  // private searchVisible: boolean = false;

  private records;
  private totals;

  private chunkLoaderTimeout;
  private searchTextTimeout;

  private detailDocumentId;
  private detailDocumentMeta;
  private adding: boolean = false;

  private reportsLoaded = new Subject<any>();
  private reportSelected = new Subject<any>();
  private reportLoaded = new Subject<any>();
  private recordsUpdated = new Subject<any>();

  private dateRangeSet = new Subject<any>();
  private sortSet = new Subject<any>();
  private detailDocumentSet = new Subject<any>();
  private addingSet = new Subject<any>();

  constructor(
    private router: Router,
    private ioService: IoService,
    private screenService: ScreenService,
    private locationService: LocationService
  ) {
    // Initialize date range to current month
    this.dateRange = {
      startDate: moment().startOf('month'),
      endDate: moment().endOf('month')
    };

    // If a location is already set, load reports
    if (this.locationService.getActiveLocation()) {
      this.loadReports();
    }

    // When the location is set, load a list of the available reports for that location
    this.locationService.activeLocationSetObservable().subscribe(activeLocation => {
      // Make sure the reports screen is active before we bother updating its contents
      if (this.screenService.getActiveScreen().key == 'reports') {
        this.loadReports();
      }
    });
  }

  init() {
    if (this.activeReportConfig) {
      this.router.navigate(['reports', this.activeReportConfig.alias]);
    }
  }

  loadReports() {
    this.ioService.post('/report/getLocationReports', {
      locationId: this.locationService.getActiveLocation()._id
    }).then((reportsData: any) => {

      // Build List of Category Names
      let categoryNames = [];
      for (let report of reportsData) {
        this.reportsByAlias[report.alias] = report;
        if (report.category && categoryNames.indexOf(report.category) == -1) {
          categoryNames.push(report.category);
        }
      }

      // Sort Category Names
      categoryNames.sort();

      // Add Other Category to the end
      categoryNames.push('Other');

      let categories = [];
      for (let categoryName of categoryNames) {
        categories.push({
          name: categoryName,
          options: [],
          active: true
        });
      }

      // Sort Reports By Name
      reportsData.sort((a, b) => (a.name > b.name) ? 1 : -1);

      var defaultBias = 0;

      for (let report of reportsData) {
        if (!report.category) {
          report.category = 'Other';
        }

        // See if this report should be the default
        if (report.default >= defaultBias) {
          this.defaultReport = report;
          defaultBias = report.default;
        }

        for (let category of categories) {
          if (report.category == category.name) {
            category.options.push(report);
          }
        }
      }

      this.reportCategories = categories;

      this.reportsLoaded.next({
        reportCategories: this.reportCategories
      });

      // See if the activeReport is still available
      if (!this.activeReportConfig || !this.reportsByAlias[this.activeReportConfig.alias]) {
        // Load the active alias or the default report if no alias is pending
        if (this.pendingReportAlias) {
          this.setReportByAlias(this.pendingReportAlias);
        } else {
          this.router.navigate(['reports', this.defaultReport.alias]);
        }
      } else {
        this.setActiveReport(this.activeReportConfig);
      }

    });
  }

  getReportCategories() {
    return this.reportCategories;
  }

  reportsLoadedObservable(): Observable<any> {
    return this.reportsLoaded.asObservable();
  }

  setReportByAlias(alias) {
    if (!this.reportsByAlias[alias]) {
      this.pendingReportAlias = alias;
    } else {
      this.setActiveReport(this.reportsByAlias[alias]);
    }
  }

  setActiveReport(reportConfig) {
    this.activeReportConfig = reportConfig;
    this.activeFields = null;
    this.records = null;
    this.totals = null;
    this.search = null;
    this.reportSelected.next(this.activeReportConfig);
    this.recordsUpdated.next(this.records);
    this.loadReport(null);
  }

  getActiveReportConfig() {
    return this.activeReportConfig;
  }

  reportSelectedObservable(): Observable<any> {
    return this.reportSelected.asObservable();
  }


  loadReport(skip) {
    // if (skip == null) {
      this.setAdding(false);
    // }

    let activeLocation = this.locationService.getActiveLocation();

    if (this.activeReportConfig && activeLocation) {

      let clientFilters = [];

      // Add Location Filter
      let activeLocationId = activeLocation._id;
      if (activeLocationId) {
        clientFilters.push({
          path: 'locationId',
          type: 'id',
          value: [
            activeLocationId
          ]
        });
      }

      // Add Date Range
      if (this.activeReportConfig.datePath) {
        clientFilters.push({
          path: this.activeReportConfig.datePath,
          type: 'dateTime',
          value: {
            start: this.dateRange.startDate,
            end: this.dateRange.endDate
          }
        });
      }

      let clientConfig = {
        skip: skip,
        clientFilters: clientFilters,
        sort: this.sorts,
        search: this.search,
        activeFields: null
      };

      // Optionaly define a custom set of optional fields to be returned from the server
      if (this.activeFields) {
        clientConfig.activeFields = [];
        for (let activeField of this.activeFields) {
          clientConfig.activeFields.push(activeField.path);
        }
      }

      // Retrieve data from the server
      this.ioService.post('/report/loadRecordsAdvanced', {
        reportId: this.activeReportConfig._id,
        utcOffset: new Date().getTimezoneOffset(),
        clientConfig
      }).then((reportData: any) => {

        if (skip == null) {
          // This is initial load of this report config
          this.activeFields = [];
          reportData.template.activeFields.forEach((fieldPath, fp) => {
            reportData.template.fields.forEach((fieldConfig, fc) => {
              if (fieldPath == fieldConfig.path) {

                if (this.sorts && this.sorts[0].path == fieldPath) {
                  fieldConfig.sort = this.sorts[0].direction;
                } else {
                  fieldConfig.sort = null;
                }

                this.activeFields.push(fieldConfig);
                return false;
              }
            });
          });

          this.totals = reportData.totals;

          this.reportLoaded.next({
            activeFields: this.activeFields,
            totals: this.totals,
            template: reportData.template
          });

          // This is the initial load, set the records to an array of empty objects
          if (this.totals) {
            this.records = Array(this.totals.count).fill({});
          }
        }

        // Put returned records into the placeholder array
        let index = 0;
        if (! reportData || !reportData.skip) {
          reportData.skip = 0;
        }
        if (!this.records) {
          this.records = [];
        }

        for (let record of reportData.records) {
          this.records[reportData.skip+index] = record;
          index++;
        }

        // console.log('records$: ', this.records);
        let records = [];
        if (this.records) {
          records = [...this.records];
        }

        this.recordsUpdated.next(records);
      });
    }
  }

  loadChunk(skip) {
    if (this.activeReportConfig) {
      if (this.chunkLoaderTimeout) {
        clearTimeout(this.chunkLoaderTimeout);
      }

      this.chunkLoaderTimeout = setTimeout(() => {
        this.loadReport(skip);
      }, 200);

    } else {
      console.log('Not ready to load chunks');
    }
  }

  reportLoadedObservable(): Observable<any> {
    return this.reportLoaded.asObservable();
  }

  recordsUpdatedObservable(): Observable<any> {
    return this.recordsUpdated.asObservable();
  }





  // Date Range
  setDateRange(dateRange) {
    this.dateRange = dateRange;
    this.reportSelected.next(this.activeReportConfig);
    this.records = null;
    this.recordsUpdated.next(this.records);
    this.loadReport(null);
    this.dateRangeSet.next(this.dateRange);
  }

  getDateRange() {
    return this.dateRange;
  }

  dateRangeSetObservable(): Observable<any> {
    return this.dateRangeSet.asObservable();
  }



  // Sort
  setSort(field) {
    // if (!this.search) {
      let direction = 1;

      if (this.sorts && this.sorts[0] && field.path == this.sorts[0].path) {
        direction = this.sorts[0].direction * -1;
      }

      this.sorts = [{
        path: field.path,
        direction: direction
      }];

      this.sortSet.next(this.sorts);
      this.records = null;
      this.loadReport(null);
    // }
  }

  sortSetObservable(): Observable<any> {
    return this.sortSet.asObservable();
  }

  setFieldsSequence(fields) {
    this.activeFields = fields;
    this.loadReport(null);
  }

  addField(field) {
    this.activeFields.push(field);
    this.loadReport(null);
  }

  removeField(field) {
    let activeIndex = this.activeFields.indexOf(field);
    if (activeIndex > -1) {
      this.activeFields.splice(activeIndex, 1);
    }
    this.loadReport(null);
  }


  // Search
  setSearch(searchText) {
    this.totals = null;
    this.records = null;
    this.recordsUpdated.next(this.records);
    this.sorts = null;
    this.sortSet.next(this.sorts);

    if (this.activeReportConfig) {
      if (this.searchTextTimeout) {
        clearTimeout(this.searchTextTimeout);
      }

      this.searchTextTimeout = setTimeout(() => {
        this.search = searchText;
        this.totals = null;
        this.records = null;
        this.recordsUpdated.next(this.records);
        this.loadReport(null);
      }, 1000);
    }
  }



  // Active Detail
  // setDetailDocumentId(id) {
  //   console.log('setDetailDocumentId: ', this.activeReportConfig);
  //   this.setDetailDocument({
  //     id: id,
  //     name: this.activeReportConfig.name,
  //     type: this.activeReportConfig.itemType
  //   });
  // }
  setDetailDocumentId(id) {
    this.detailDocumentId = id;
  }

  getDetailDocumentId() {
    return this.detailDocumentId;
  }

  // setDetailDocument(documentMeta) {
  //   this.detailDocumentMeta = documentMeta;
  //   this.detailDocumentSet.next(this.detailDocumentMeta);
  // }

  // getDetailDocumentId() {
  //   if (this.detailDocumentMeta) {
  //     return this.detailDocumentMeta.id;
  //   } else {
  //     return null;
  //   }
  // }

  detailDocumentSetObservable(): Observable<any> {
    return this.detailDocumentSet.asObservable();
  }


  setAdding(adding) {
    this.adding = adding;
    this.addingSet.next(this.adding);
  }

  addingSetObservable(): Observable<any> {
    return this.addingSet.asObservable();
  }


  download() {
    if (this.activeReportConfig) {

      let clientFilters = [];

      // Add Location Filter
      let activeLocationId = this.locationService.getActiveLocation()._id;
      if (activeLocationId) {
        clientFilters.push({
          path: 'locationId',
          type: 'id',
          value: [
            activeLocationId
          ]
        });
      }

      // Add Date Range
      if (this.activeReportConfig.datePath) {
        clientFilters.push({
          path: this.activeReportConfig.datePath,
          type: 'dateTime',
          value: {
            start: this.dateRange.startDate,
            end: this.dateRange.endDate
          }
        });
      }

      let clientConfig = {
        clientFilters: clientFilters,
        sort: this.sorts,
        search: this.search,
        activeFields: null,
        download: true
      };

      // Optionaly define a custom set of optional fields to be returned from the server
      if (this.activeFields) {
        clientConfig.activeFields = [];
        for (let activeField of this.activeFields) {
          clientConfig.activeFields.push(activeField.path);
        }
      }

      // Retrieve data from the server
      this.ioService.download('/report/loadRecordsAdvanced', {
        reportId: this.activeReportConfig._id,
        utcOffset: new Date().getTimezoneOffset(),
        clientConfig
      }, this.activeReportConfig.name+'.csv');
    } else {
      console.log('No active report to download');
    }
  }


  refresh() {
    this.totals = null;
    this.records = null;
    this.recordsUpdated.next(this.records);
    this.loadReport(null);
    // this.sortSet.next(this.sorts);
  }

}
