import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { Router, ActivatedRoute } from '@angular/router';
import { IoService } from '../../../services/io/io.service';
import { LocationService } from '../../../services/location/location.service';

@Component({
  selector: 'app-autoresponder',
  templateUrl: './autoresponder.component.html',
  styleUrls: ['./autoresponder.component.scss']
})
export class AutoresponderComponent implements OnInit {

  charLimit = 160;
  activeLocationSet: Subscription;
  location;
  saving;

  constructor(
    private router: Router,
    private ioService: IoService,
    private locationService: LocationService
  ) { }

  ngOnInit() {
    this.activeLocationSet = this.locationService.activeLocationSetObservable().subscribe(locationSet => {
      this.getSettings();
    });
    this.getSettings();
  }

  ngOnDestroy() {
    if (this.activeLocationSet) {
      this.activeLocationSet.unsubscribe();
    }
  }

  close() {
    this.router.navigate(['marketing', 'dashboard']);
  }

  getSettings() {
    return new Promise((resolve, reject) => {
      this.saving = false;
      let activeLocation = this.locationService.getActiveLocation();
      if (!activeLocation || !activeLocation._id) {
        this.close();
      } else {
        this.ioService.post('/marketing/getSettings', {
          locationId: activeLocation._id
        }).then((settingsResponse: any) => {
          console.log('settingsResponse: ', settingsResponse);
          let location = settingsResponse.location;

          // Apply defaults
          if (!location.autoresponder) {
            location.autoresponder = {
              active: false,
              content: 'This is an unmonitored number. For assistance please call '+this.formatTelephone(location.telephone)+'.'
            }
          }

          this.location = location;
          resolve();
          console.log(this.location);
        });
      }
    });
  }

  formatTelephone(telephone) {
    telephone = String(telephone);
    telephone = telephone.replace(/\D/g,'');
    let formattedTelephone = '('+telephone.substring(0, 3)+') '+telephone.substring(3, 6)+'-'+telephone.substring(6, 10);
    return formattedTelephone;
  }

  contentChanged() {
    console.log('contentChanged');
    if (this.location.autoresponder.content.length > this.charLimit) {
       this.location.autoresponder.content = this.location.autoresponder.content.substring(0, this.charLimit);
    }
  }

  toggleAutoresponder(active) {
    if (active == null) {
      this.location.autoresponder.active = !this.location.autoresponder.active;
    } else {
      this.location.autoresponder.active = active;
    }
  }

  save() {
    return new Promise((resolve, reject) => {
      if (!this.saving) {
        this.saving = true;

        this.ioService.post('/marketing/saveSettings', {
          location: this.location
        }).then((saveResponse: any) => {
          console.log('saveResponse: ', saveResponse);

          this.saving = false;
          this.close();
          resolve();
          console.log(this.location);
        }).catch(error => {
          this.saving = false;
          reject(error);
        });

      } else {
        reject({
          msg: 'Already saving.'
        });
      }
    });
  }

}
