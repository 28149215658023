import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

// RF Modules
import { AuthenticationModule } from './modules/authentication/authentication.module';
import { DashboardModule } from './modules/dashboard/dashboard.module';
import { FulfillmentModule } from './modules/fulfillment/fulfillment.module';
import { MarketingModule } from './modules/marketing/marketing.module';
import { ReportModule } from './modules/report/report.module';
import { PurchaseModule } from './modules/purchase/purchase.module';
import { SharedModule } from './modules/shared/shared.module';
import { VirtualTerminalModule } from './modules/virtual-terminal/virtual-terminal.module';
import { AdminModule } from './modules/admin/admin.module';

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    AppRoutingModule,
    AuthenticationModule,
    DashboardModule,
    FulfillmentModule,
    MarketingModule,
    ReportModule,
    PurchaseModule,
    SharedModule,
    VirtualTerminalModule,
    AdminModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
