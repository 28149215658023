import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { ChartDataSets, ChartOptions } from 'chart.js';
import { Color, Label } from 'ng2-charts';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';

import { IoService } from '../../../services/io/io.service';
import { LocationService } from '../../../services/location/location.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {

  activeLocationSet: Subscription;
  locationId;
  minAgo;
  minAgoInterval;

  // Sales Chart
  public salesChartData: ChartDataSets[] = [
    {
      data: [],
      label: ''
    },
  ];
  public salesChartLabels: Label[] = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
  public salesChartOptions: ChartOptions = {
    responsive: true,
    scales: {
      yAxes: [{
        ticks: {
          beginAtZero: true,
          callback: (value, index, values) => {
            if (parseInt(value) >= 1000){
              return '$' + value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            } else {
              return '$' + value;
            }
          }
        }
      }]
    }
  };
  public salesChartColors: Color[] = [
    {
      borderColor: 'black',
      backgroundColor: 'rgba(255,0,0,0.3)',
    },
  ];
  public salesChartLegend = true;
  public salesChartType = 'line';
  public salesChartPlugins = [];


  // Recent Chart
  public recentChartData: ChartDataSets[] = [
    {
      data: [],
      label: ''
    },
  ];
  public recentChartLabels: Label[] = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
  public recentChartOptions: ChartOptions = {
    responsive: true,
    scales: {
      yAxes: [{
        id: 'A',
        type: 'linear',
        position: 'left',
        ticks: {
          beginAtZero: true,
          callback: (value, index, values) => {
            if (parseInt(value) >= 1000){
              return '$' + value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            } else {
              return '$' + value;
            }
          }
        }
      },
      {
        id: 'B',
        type: 'linear',
        position: 'right',
        scaleLabel: {
          display: true,
          labelString: 'Messages Sent'
        },
        ticks: {
          beginAtZero: true,
          callback: (value, index, values) => {
            if (parseInt(value) >= 1000){
              return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            } else {
              return value;
            }
          }
        }
      }
    ]
    }
  };
  public recentChartColors: Color[] = [
    {
      borderColor: 'black',
      backgroundColor: 'rgba(8,123,29,0.3)',
    },
    {
      borderColor: '#555555',
      backgroundColor: 'rgba(100,100,100,0.3)',
    },
  ];
  public recentChartLegend = true;
  public recentChartType = 'line';
  public recentChartPlugins = [];



  public uniqueCustomers;
  public anniversaries;
  public smsMtd;
  public smsLastMonth;
  public birthdays;
  public loyaltyIssuedYtd;
  public loyaltyRedeemedMtd;
  public trendingPromoCodes = [];

  public smsPhone;

  public emailCredits;
  public smsCredits;


  constructor(
    private ioService: IoService,
    private locationService: LocationService,
    private router: Router
  ) { }

  ngOnInit() {
    this.activeLocationSet = this.locationService.activeLocationSetObservable().subscribe(locationSet => {
      this.loadMarketingSummary();
    });
    if (this.locationService.getActiveLocation()) {
      this.loadMarketingSummary();
    }
  }

  ngOnDestroy() {
    if (this.activeLocationSet) {
      this.activeLocationSet.unsubscribe();
    }

    if (this.minAgoInterval) {
      clearInterval(this.minAgoInterval);
    }
  }

  loadMarketingSummary() {
    console.log('loadMarketingSummary: ');
    this.locationId = this.locationService.getActiveLocation()._id;
    this.ioService.post('/marketing/getSummary', {
      locationId: this.locationId,
      utcOffset: new Date().getTimezoneOffset(),
      components: {}
    }).then((summaryResponse: any) => {
      console.log('summaryResponse: ', summaryResponse);
      this.salesChartLabels = summaryResponse.historyChart.labels;
      this.salesChartData = summaryResponse.historyChart.data;

      this.recentChartLabels = summaryResponse.dailyChart.labels;
      this.recentChartData = summaryResponse.dailyChart.data;

      this.uniqueCustomers = summaryResponse.uniqueCustomers;
      this.anniversaries = summaryResponse.anniversaries;
      this.smsMtd = summaryResponse.smsMtd;
      this.smsLastMonth = summaryResponse.smsLastMonth;
      this.birthdays = summaryResponse.birthdays;
      this.loyaltyIssuedYtd = summaryResponse.loyaltyIssuedYtd;
      this.loyaltyRedeemedMtd = summaryResponse.loyaltyRedeemedMtd;
      this.trendingPromoCodes = summaryResponse.trendingPromoCodes;
      this.smsPhone = summaryResponse.smsPhone;

      this.emailCredits = summaryResponse.emailCredits;
      this.smsCredits = summaryResponse.smsCredits;

      this.minAgo = 0;
      if (this.minAgoInterval) {
        clearInterval(this.minAgoInterval);
      }
      this.minAgoInterval = setInterval(() => {
        this.minAgo++;
        if (this.minAgo > 10) {
          this.loadMarketingSummary();
        }
      }, 60000);
    });
  }

  addCommunication() {
    console.log('Add Communication!');
    this.ioService.post('/communication/addCommunication', {
      locationId: this.locationId
    }).then((addResponse: any) => {
      console.log('addResponse: ', addResponse);
      this.router.navigate(['marketing', 'communication', addResponse.communicationId]);
    });
  }

  settingsClick() {
    this.router.navigate(['marketing', 'settings']);
  }

}
