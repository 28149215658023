import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { Observable, Subject, Subscription, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';

import { HistoryService } from '../../../services/history/history.service';
import { IoService } from '../../../services/io/io.service';
import { ReportService } from '../../../services/report/report.service';
import { AuthorizationService } from '../../../services/authorization/authorization.service';

@Component({
  selector: 'app-detail-employee',
  templateUrl: './detail-employee.component.html',
  styleUrls: ['./detail-employee.component.scss']
})
export class DetailEmployeeComponent implements OnInit {

  documentId$: Observable<any>;
  documentId;

  showBackButton;

  user;
  savingUser;
  sendingPin;

  manageUsersLocations;

  constructor(
    private locationR: Location,
    private route: ActivatedRoute,
    private router: Router,
    private historyService: HistoryService,
    private authorizationService: AuthorizationService,
    private ioService: IoService,
    private reportService: ReportService
  ) { }

  ngOnInit() {
    this.documentId$ = this.route.paramMap.pipe(switchMap(params => of(params.get('documentId'))));
    this.documentId$.subscribe(documentId => {
      if (documentId) {
        this.documentId = documentId;
        // this.loadUserDetail();
        this.init();
      }
    });

    if (this.historyService.getPreviousLinkDepth() > 3) {
      this.showBackButton = true;
    }
  }

  close() {
    this.router.navigate(['../../'], { relativeTo: this.route });
  }

  back() {
    this.locationR.back();
  }

  init() {
    this.loadUserDetail();
  }

  loadUserDetail() {
    this.user = null;
    this.savingUser = null;
    this.sendingPin = null;

    this.ioService.post('/user/getDetails', {
      userId: this.documentId
    }).then((userResponse: any) => {
      console.log('userResponse: ', userResponse);

      this.user = userResponse.user;
      this.manageUsersLocations = userResponse.manageUsersLocations;
    });
  }

  sendPin() {
    console.log('sendPin: ');
    if (!this.sendingPin) {
      this.sendingPin = true;
      this.saveUser().then(() => {
        this.ioService.post('/authentication/setPin', {
          userId: this.user._id,
          sendToUser: true
        }).then((pinResponse: any) => {
          console.log('pinResponse: ', pinResponse);
          this.user.pinSent = true;
          this.sendingPin = false;
        }).catch(() => {
          this.sendingPin = false;
        });
      });
    }
  }

  deleteUser() {
    console.log('deleteUser: ');
    return new Promise((resolve, reject) => {
      this.ioService.post('/user/deleteUser', {
        userId: this.user._id
      }).then((deleteResponse: any) => {
        this.reportService.loadReport(0);
        this.close();
        resolve();
      }).catch((deleteError: any) => {
        console.log('ERROR: '+deleteError);
        alert('Error Deleting User');
        reject();
      });
    });
  }

  saveUser() {
    return new Promise((resolve, reject) => {
      if (!this.savingUser) {
        this.savingUser = true;
        this.ioService.post('/user/saveChanges', {
          user: this.user
        }).then((saveResponse: any) => {
          this.savingUser = false;
          this.reportService.loadReport(0);
          resolve();
        }).catch((error: any) => {
          console.log('ERROR: '+error);
          alert('Error Saving User');
          this.savingUser = false;
          reject();
        });
      }
    });
  }

  authorizeLocation(locationId) {
    console.log('authorizeLocation: ', locationId);

    this.ioService.post('/user/addUserLocation', {
      userId: this.user._id,
      locationId: locationId
    }).then((addLocationResponse: any) => {


      this.ioService.post('/user/applyPermissionGroup', {
        userId: this.user._id,
        locationId: locationId,
        groupName: 'Studio Employee'
      }).then((addLocationResponse: any) => {

        this.loadUserDetail();

      }).catch((error: any) => {
        console.log('ERROR: '+error);
        alert('Error Granting Access to Location');
      });


    }).catch((error: any) => {
      console.log('ERROR: '+error);
      alert('Error Granting Access to Location');
    });

  }

  deauthorizeLocation(locationId) {
    if (this.user.locationId.length > 1) {

      this.ioService.post('/user/removeUserLocation', {
        userId: this.user._id,
        locationId: locationId
      }).then((removeLocationResponse: any) => {

        this.loadUserDetail();

      }).catch((error: any) => {
        console.log('ERROR: '+error);
        alert('Error Removing Access to Location');
      });

    } else {
      alert('Cannot remove all locations. Delete user to deauthorize everywhere.');
    }
  }

}
