import { Component, OnInit, OnDestroy } from '@angular/core';

import { Subscription } from 'rxjs';
import { DomSanitizer } from '@angular/platform-browser';

import { IoService } from '../../services/io/io.service';
import { LocationService } from '../../services/location/location.service';

import { MessageService } from '../../services/message/message.service';

import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-virtual-terminal',
  templateUrl: './virtual-terminal.component.html',
  styleUrls: ['./virtual-terminal.component.scss']
})
export class VirtualTerminalComponent implements OnInit {

  activeLocationSet: Subscription;

  oosKey;
  locationId;
  oosUrl;

  transaction;

  processing;
  errors;

  customerSearchTimeout;

  stateOptions = [
    {
        "name": "Alabama",
        "abbreviation": "AL"
    },
    {
        "name": "Alaska",
        "abbreviation": "AK"
    },
    {
        "name": "American Samoa",
        "abbreviation": "AS"
    },
    {
        "name": "Arizona",
        "abbreviation": "AZ"
    },
    {
        "name": "Arkansas",
        "abbreviation": "AR"
    },
    {
        "name": "California",
        "abbreviation": "CA"
    },
    {
        "name": "Colorado",
        "abbreviation": "CO"
    },
    {
        "name": "Connecticut",
        "abbreviation": "CT"
    },
    {
        "name": "Delaware",
        "abbreviation": "DE"
    },
    {
        "name": "District Of Columbia",
        "abbreviation": "DC"
    },
    {
        "name": "Florida",
        "abbreviation": "FL"
    },
    {
        "name": "Georgia",
        "abbreviation": "GA"
    },
    {
        "name": "Hawaii",
        "abbreviation": "HI"
    },
    {
        "name": "Idaho",
        "abbreviation": "ID"
    },
    {
        "name": "Illinois",
        "abbreviation": "IL"
    },
    {
        "name": "Indiana",
        "abbreviation": "IN"
    },
    {
        "name": "Iowa",
        "abbreviation": "IA"
    },
    {
        "name": "Kansas",
        "abbreviation": "KS"
    },
    {
        "name": "Kentucky",
        "abbreviation": "KY"
    },
    {
        "name": "Louisiana",
        "abbreviation": "LA"
    },
    {
        "name": "Maine",
        "abbreviation": "ME"
    },
    {
        "name": "Marshall Islands",
        "abbreviation": "MH"
    },
    {
        "name": "Maryland",
        "abbreviation": "MD"
    },
    {
        "name": "Massachusetts",
        "abbreviation": "MA"
    },
    {
        "name": "Michigan",
        "abbreviation": "MI"
    },
    {
        "name": "Minnesota",
        "abbreviation": "MN"
    },
    {
        "name": "Mississippi",
        "abbreviation": "MS"
    },
    {
        "name": "Missouri",
        "abbreviation": "MO"
    },
    {
        "name": "Montana",
        "abbreviation": "MT"
    },
    {
        "name": "Nebraska",
        "abbreviation": "NE"
    },
    {
        "name": "Nevada",
        "abbreviation": "NV"
    },
    {
        "name": "New Hampshire",
        "abbreviation": "NH"
    },
    {
        "name": "New Jersey",
        "abbreviation": "NJ"
    },
    {
        "name": "New Mexico",
        "abbreviation": "NM"
    },
    {
        "name": "New York",
        "abbreviation": "NY"
    },
    {
        "name": "North Carolina",
        "abbreviation": "NC"
    },
    {
        "name": "North Dakota",
        "abbreviation": "ND"
    },
    {
        "name": "Northern Mariana Islands",
        "abbreviation": "MP"
    },
    {
        "name": "Ohio",
        "abbreviation": "OH"
    },
    {
        "name": "Oklahoma",
        "abbreviation": "OK"
    },
    {
        "name": "Oregon",
        "abbreviation": "OR"
    },
    {
        "name": "Palau",
        "abbreviation": "PW"
    },
    {
        "name": "Pennsylvania",
        "abbreviation": "PA"
    },
    {
        "name": "Puerto Rico",
        "abbreviation": "PR"
    },
    {
        "name": "Rhode Island",
        "abbreviation": "RI"
    },
    {
        "name": "South Carolina",
        "abbreviation": "SC"
    },
    {
        "name": "South Dakota",
        "abbreviation": "SD"
    },
    {
        "name": "Tennessee",
        "abbreviation": "TN"
    },
    {
        "name": "Texas",
        "abbreviation": "TX"
    },
    {
        "name": "Utah",
        "abbreviation": "UT"
    },
    {
        "name": "Vermont",
        "abbreviation": "VT"
    },
    {
        "name": "Virgin Islands",
        "abbreviation": "VI"
    },
    {
        "name": "Virginia",
        "abbreviation": "VA"
    },
    {
        "name": "Washington",
        "abbreviation": "WA"
    },
    {
        "name": "West Virginia",
        "abbreviation": "WV"
    },
    {
        "name": "Wisconsin",
        "abbreviation": "WI"
    },
    {
        "name": "Wyoming",
        "abbreviation": "WY"
    }
  ];

  conversationActive;
  conversationActiveUpdated: Subscription;

  constructor(
    private ioService: IoService,
    private locationService: LocationService,
    public sanitizer: DomSanitizer,
    private messageService: MessageService
  ) { }

  ngOnInit() {
    this.activeLocationSet = this.locationService.activeLocationSetObservable().subscribe(locationSet => {
      this.requestOosKey();
    });
    if (this.locationService.getActiveLocation()) {
      this.requestOosKey();
    }

    this.conversationActiveUpdated = this.messageService.conversationActiveUpdatedObservable().subscribe(conversationActive => {
      this.conversationActive = conversationActive;
    });

    this.conversationActive = this.messageService.getConversationActive();
  }

  ngOnDestroy() {
    if (this.activeLocationSet) {
      this.activeLocationSet.unsubscribe();
    }

    if (this.conversationActiveUpdated) {
      this.conversationActiveUpdated.unsubscribe();
    }
  }

  getOosUrl() {
    let iframeUrl = environment.oosUrl+'form?clientId='+environment.clientId+'&locationId='+this.locationId+'&key='+this.oosKey;
    return this.sanitizer.bypassSecurityTrustResourceUrl(iframeUrl);
  }

  requestOosKey() {
    this.oosKey = null;
    this.transaction = null;
    this.errors = null;
    this.customerSearchTimeout = null;
    this.locationId = this.locationService.getActiveLocation()._id;
    if (this.locationId) {
      this.ioService.post('/transaction/credit/check', {
        locationId: this.locationId,
      }).then((result: any) => {
        console.log(result);
        // Create a fresh transaction object
        this.errors = this.newErrors();
        this.transaction = this.newTransaction();

        this.oosKey = result.key;
        this.oosUrl = this.getOosUrl();

        // Set the OOS key on the active transaction
        this.transaction.payment.key = this.oosKey;


      }).catch((err: any) => {
        console.log(err);
      });
    }
  }

  newErrors() {
    return {
      csc: null,
      postalCode: null,
      streetAddress: null,
      amount: null
    };
  }

  newTransaction() {
    return {
      locationId: this.locationId,
      ip: null,
      amount: null,
      payment: {
        key: null,
        csc: null,
      },
      customer: {
        email: null,
        telephone: null,
        firstName: null,
        lastName: null
      },
      billing: {
        firstName: null,
        lastName: null,
        streetAddress: null,
        addressLocality: null,
        addressRegion: null,
        postalCode: null
      },
      custom: null,
      description: null
    };
  }

  customerPhoneKeyUp() {
    if (this.transaction.customer.telephone && this.transaction.customer.telephone.length == 10) {
      this.customerSearch();
    }
  }

  customerEmailKeyUp() {
    // The email input has been changed by the user
    // Cancel any existing customer lookup requests
    if (this.customerSearchTimeout) {
      clearTimeout(this.customerSearchTimeout);
    }

    // If the user has typed at least 9 characters in this field, then initiate a customer search
    if (this.transaction.customer.email && this.transaction.customer.email.length > 8) {
      this.customerSearchTimeout = setTimeout(() => {
        this.customerSearch();
      }, 500);
    }
  }

  customerSearch() {
    this.ioService.post('/customer/search', {
      telephone: this.transaction.customer.telephone,
      email: this.transaction.customer.email
    }).then((result: any) => {
      console.log(result);
      if (result) {
        this.loadCustomer(result);
      } else {
        this.clearCustomer();
      }
    }).catch((err: any) => {
      console.log(err);
      this.clearCustomer();
    });
  }

  clearCustomer() {
    // this.transaction.customer
  }

  loadCustomer(customer) {
    console.log('found customer: ', customer);
    this.transaction.customer = customer;
    if (customer.address) {
      this.transaction.billing = customer.address;
    }
  }

  cancelClick() {
    this.requestOosKey();
  }

  chargeClick() {
    if (!this.processing) {
      this.processing = true;

      this.errors = {};

      if (!this.transaction.amount || this.transaction.amount == 0) {
        this.errors.amount = 'Amount is required';
      }

      if (!this.transaction.payment.csc || this.transaction.payment.csc.length < 3) {
        this.errors.csc = 'CVV is required';
      }

      // if (!this.transaction.billing.streetAddress || this.transaction.billing.streetAddress.length < 3) {
      //   this.errors.streetAddress = 'Street Address is required';
      // }

      if (!this.transaction.billing.postalCode || this.transaction.billing.postalCode.length < 5) {
        this.errors.postalCode = 'Zip code is required';
      }

      if (Object.entries(this.errors).length === 0 && this.errors.constructor === Object) {
        // There are no validation errors
        this.creditSale();
      } else {
        this.processing = false;
      }
    }
  }

  creditSale() {

    // Copy customer name to billing name
    if (this.transaction.customer.firstName) {
      this.transaction.billing.firstName = this.transaction.customer.firstName;
      this.transaction.billing.lastName = this.transaction.customer.lastName;
    }

    this.ioService.post('/transaction/credit/subtract', this.transaction).then((result: any) => {
      console.log(result);
      result.success = true;
      this.transaction.response = result;
      this.processing = false;

    }).catch((err: any) => {
      console.log(err);
      err.success = false;
      this.transaction.response = err.error;
      this.processing = false;

    });
  }

}
