import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { Observable, Subject, Subscription, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';

import { HistoryService } from '../../../services/history/history.service';
import { IoService } from '../../../services/io/io.service';
import { ReportService } from '../../../services/report/report.service';
import { LocationService } from '../../../services/location/location.service';

@Component({
  selector: 'app-detail-inventory',
  templateUrl: './detail-inventory.component.html',
  styleUrls: ['./detail-inventory.component.scss']
})
export class DetailInventoryComponent implements OnInit {

    documentId$: Observable<any>;
    documentId;

    showBackButton;

    inventory;
    saving;
    errors = [];

    selectingImage;

    taxes = {
      available: [],
      selected: [],
      settings: {
        singleSelection: false,
        text: 'Select Taxes',
        enableSearchFilter: true,
        addNewItemOnFilter: false,
        classes: 'myclass multiselect'
      }
    };

    staticCategories = [
      {
        name: 'B&b Merchandise',
        royaltyEligible: false
      },
      {
        name: 'Beverage',
        royaltyEligible: false
      },
      {
        name: 'Component',
        royaltyEligible: true
      },
      {
        name: 'Fees',
        royaltyEligible: true
      },
      {
        name: 'Food',
        royaltyEligible: false
      },
      {
        name: 'Gift Certificate',
        royaltyEligible: true
      },
      {
        name: 'Instruction',
        royaltyEligible: true
      },
      {
        name: 'Private Party Deposit',
        royaltyEligible: true
      },
      {
        name: 'Projects',
        royaltyEligible: true
      },
      {
        name: 'Shipping',
        royaltyEligible: false
      }
    ];

    staticCategory;

    // categories = {
    //   available: [],
    //   selected: [],
    //   settings: {
    //     singleSelection: false,
    //     text: 'Select Categories',
    //     selectAllText:'Select All',
    //     unSelectAllText: 'Deselect All',
    //     enableSearchFilter: true,
    //     addNewItemOnFilter: true,
    //     classes: 'myclass multiselect'
    //   },
    //   add: (data: string) => {
    //     let newItem = {
    //       id: this.categories.available.length+1,
    //       itemName: data,
    //       name: data
    //     };
    //     this.categories.available.push(newItem);
    //     this.categories.selected.push(newItem);
    //   }
    // };

    keywords = {
      available: [],
      selected: [],
      settings: {
        singleSelection: false,
        text: 'Select Keywords',
        selectAllText:'Select All',
        unSelectAllText: 'Deselect All',
        enableSearchFilter: true,
        addNewItemOnFilter: true,
        classes: 'myclass multiselect'
      },
      add: (data: string) => {
        let newItem = {
          id: this.keywords.available.length+1,
          itemName: data,
          name: data
        };
        this.keywords.available.push(newItem);
        this.keywords.selected.push(newItem);
      }
    };

    cdnUrl = 'https://cdn.rftapi.com/client/sand847543/items/';

    images = [
      {
        name: 'Beer',
        filename: 'Beer.jpg'
      },
      {
        name: 'Cider',
        filename: 'Cider.jpg'
      },
      {
        name: 'Coffee',
        filename: 'Coffee.jpg'
      },
      {
        name: 'Component',
        filename: 'Component.jpg'
      },
      {
        name: 'Fees',
        filename: 'Fees.jpg'
      },
      {
        name: 'Gift Certificate',
        filename: 'Gift-Certificate.jpg'
      },
      {
        name: 'Instruction',
        filename: 'Instruction.jpg'
      },
      {
        name: 'Merchandise',
        filename: 'Merchandise.jpg'
      },
      {
        name: 'Pop',
        filename: 'Pop.jpg'
      },
      {
        name: 'Project',
        filename: 'Project.jpg'
      },
      {
        name: 'Seltzer',
        filename: 'Seltzer.jpg'
      },
      {
        name: 'Shipping',
        filename: 'Shipping.jpg'
      },
      {
        name: 'Snacks',
        filename: 'Snacks.jpg'
      },
      {
        name: 'Soda',
        filename: 'Soda.jpg'
      },
      // {
      //   name: 'Water',
      //   filename: 'Water.jpg'
      // },
      {
        name: 'Wine',
        filename: 'Wine.jpg'
      }
    ];


    constructor(
      private route: ActivatedRoute,
      private router: Router,
      private ioService: IoService,
      private historyService: HistoryService,
      private locationService: LocationService,
      private reportService: ReportService
    ) { }

    ngOnInit() {
      this.documentId$ = this.route.paramMap.pipe(switchMap(params => of(params.get('documentId'))));
      this.documentId$.subscribe(documentId => {
        if (documentId) {
          this.documentId = documentId;
          this.loadInventoryDetail();
        }
      });

      if (this.historyService.getPreviousLinkDepth() > 3) {
        this.showBackButton = true;
      }
    }

    close() {
      this.router.navigate(['../../'], { relativeTo: this.route });
    }

    loadInventoryDetail() {
      console.log('loadInventoryDetail: ');

      this.selectingImage = false;

      this.taxes.selected = [];
      this.taxes.available = [];
      this.errors = [];

      // this.categories.selected = [];
      // this.categories.available = [];
      this.staticCategory = null;

      this.keywords.selected = [];
      this.keywords.available = [];

      this.ioService.post('/item/getItem', {
        itemId: this.documentId,
        getKeywords: true,
        // getCategories: true,
        getTaxes: true
      }).then((itemResponse: any) => {
        console.log('itemResponse: ', itemResponse);

        itemResponse.item.price = this.toCurrency(itemResponse.item.price);

        // Prepare Taxes
        if (itemResponse.taxes) {
          for (let tax of itemResponse.taxes) {
            let taxObject = {
              id: tax._id,
              itemName: tax.name+' ('+tax.percentage+'%)'
            };
            this.taxes.available.push(taxObject);
            if (itemResponse.item.taxes && itemResponse.item.taxes.indexOf(tax._id) != -1) {
              this.taxes.selected.push(taxObject);
            }
          }
        }

        // Prepare Categories
        // if (itemResponse.categories) {
        //   let categoryIndex = 0;
        //   for (let category of itemResponse.categories) {
        //     categoryIndex++;
        //     let categoryObject = {
        //       id: categoryIndex,
        //       itemName: category
        //     };
        //     this.categories.available.push(categoryObject);
        //     if (itemResponse.item.categories && itemResponse.item.categories.indexOf(category) != -1) {
        //       this.categories.selected.push(categoryObject);
        //     }
        //   }
        // }

        if (itemResponse.item.categories.length > 0) {
          this.staticCategory = itemResponse.item.categories[0];
        }



        // Prepare Keywords
        if (itemResponse.keywords) {
          let keywordIndex = 0;
          for (let keyword of itemResponse.keywords) {
            keywordIndex++;
            let keywordObject = {
              id: keywordIndex,
              itemName: keyword
            }
            this.keywords.available.push(keywordObject);
            if (itemResponse.item.keywords && itemResponse.item.keywords.indexOf(keyword) != -1) {
              this.keywords.selected.push(keywordObject);
            }
          }
        }


        // Lookup location of this inventory item
        if (itemResponse.item.locationId && itemResponse.item.locationId[0]) {
          let itemLocation = this.locationService.getLocation(itemResponse.item.locationId[0]);
          // console.log('itemLocation:', itemLocation);
          itemResponse.item.locationName = itemLocation.name;
        }


        this.inventory = itemResponse.item;

      });
    }

    saveInventory() {
      if (!this.saving) {
        console.log('save item');

        // Validate Category and image selection
        let errors = [];

        if (this.inventory.active !== false) {

          if (this.inventory.name == '' || this.inventory.name == 'New Item') {
            errors.push('Please set an item name.');
          }

          if (this.staticCategory == null) {
            errors.push('Please select a category.');
          }

          if (this.inventory.image == '') {
            errors.push('Please select an image.');
          }

        }

        this.errors = errors;

        if (this.errors.length > 0) {

        } else {
          this.saving = true;

          let updateItem = JSON.parse(JSON.stringify(this.inventory));

          updateItem.price = this.toInt(updateItem.price);
          if (updateItem.price < 0) {
            updateItem.price = updateItem.price*-1;
          }
          updateItem.taxes = this.taxes.selected.map(a => a.id);
          // updateItem.categories = this.categories.selected.map(a => a.itemName);

          // Handle Royalty Eligible based on category
          updateItem.categories = [this.staticCategory];

          for (let categoryCheck of this.staticCategories) {
            if (categoryCheck.name == this.staticCategory) {
              updateItem.royaltyEligible = categoryCheck.royaltyEligible;
            }
          }

          updateItem.keywords = this.keywords.selected.map(a => a.itemName);

          this.ioService.post('/item/updateItem', {
            item: updateItem
          }).then((updateResponse: any) => {
            console.log('updateResponse: ', updateResponse);
            this.saving = false;
            this.reportService.loadReport(0);
            this.close();
          });
        }

      }
    }

    deleteInventoryItem() {
      this.inventory.active = false;
      this.saveInventory();
      setTimeout(() => {
        this.close();
      }, 750);

    //   if (!this.saving) {
    //     console.log('delete item');
    //     this.saving = true;
    //
    //     this.ioService.post('/item/deleteItem', {
    //       itemId: this.inventory._id
    //     }).then((deleteResponse: any) => {
    //       console.log('deleteResponse: ', deleteResponse);
    //       this.close()
    //     });
    //
    //   }
    }

    toInt(inputValue) {
      return Math.round(inputValue*100);
    }

    toCurrency(inputValue) {
      if (!inputValue) {
        inputValue = 0;
      }
      return (inputValue/100).toFixed(2);
    }


    setItemImg(filename) {
      this.inventory.image = filename;
      console.log(this.inventory);
      this.toggleImageSelecting();
    }

    toggleImageSelecting() {
      this.selectingImage = !this.selectingImage;
    }






}
