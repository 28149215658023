import { Component, OnInit, Input, ElementRef, ViewChild } from '@angular/core';

import { IoService } from '../../services/io/io.service';
import { LocationService } from '../../services/location/location.service';

@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.scss']
})
export class AdminComponent implements OnInit {

  testRoute = '/transaction/gift/check';
  permission = '';

  conditionalPermissions = {
    action: 'grant',
    find: 'location.ledger.gift.subtract',
    change: 'location.ledger.gift.check'
  };

  importConfig = {
    minId: 0,
    limit: 1
  };

  promoConfig = {
    name: 'Loyalty Reward',
    description: 'Automatic Loyalty Reward',
    amount: 68,
    validDayCount: 365,
    locationId: null
  };

  unimportingTransactions;

  @ViewChild('unimport', { static: false }) unimportEl: ElementRef<HTMLElement>;

  constructor(
    private ioService: IoService,
    private locationService: LocationService
  ) { }

  ngOnInit() {
  }

  patchAccountsType() {
    console.log('patchAccountsType: ');
    this.ioService.post('/account/patchAccountsType', {}).then((result: any) => {
      console.log(result);
    });
  }

  patchAccountsLocationId() {
    console.log('patchAccountsLocationId: ');
    this.ioService.post('/account/patchAccountsLocationId', {}).then((result: any) => {
      console.log(result);
    });
  }

  patchCustomerPhone() {
    console.log('patchCustomerPhone: ');
    this.ioService.post('/customer/patchCustomerPhone', {}).then((result: any) => {
      console.log(result);
    });
  }

  authorizeRoot() {
    console.log('authorizeRoot: '+this.permission);
    this.ioService.post('/authorization/authorizeRoot', {
      permission: this.permission
    }).then((result: any) => {
      console.log(result);
    }).catch((err: any) => {
      console.log(err);
    });
  }

  conditionalPermissionsTest() {
    console.log('conditionalPermissionsTest: ');
    console.log(this.conditionalPermissions);
    this.ioService.post('/authorization/conditionalPermissionsTest', {
      conditionalPermissions: this.conditionalPermissions
    }).then((result: any) => {
      console.log(result);
    }).catch((err: any) => {
      console.log(err);
    });
  }

  conditionalPermissionsUpdate() {
    console.log('conditionalPermissionsUpdate: ');
    console.log(this.conditionalPermissions);
    this.ioService.post('/authorization/conditionalPermissionsUpdate', {
      conditionalPermissions: this.conditionalPermissions
    }).then((result: any) => {
      console.log(result);
    }).catch((err: any) => {
      console.log(err);
    });
  }

  postToRoute() {
    console.log(this.testRoute);
    this.ioService.post(this.testRoute, {
      locationId: this.locationService.getActiveLocation()._id,
      cardNumber: '7784510002457151992',
      amount: 1
    }).then((result: any) => {
      console.log(result);
    }).catch((err: any) => {
      console.log(err.error.msg);
    });
  }

  postToRepeatedly() {
    setInterval(() => {
      this.ioService.post(this.testRoute, {
        locationId: this.locationService.getActiveLocation()._id,
        cardNumber: '7784510002457151992',
        amount: Math.random()
      }).then((result: any) => {
        console.log(result);
      }).catch((err: any) => {
        console.log(err.error.msg);
      });
    }, 500);
  }

  generateMonthEnd() {
    let d = new Date();
    let newMonth = d.getMonth() - 1;
    if (newMonth < 0){
        newMonth += 12;
        d.setFullYear(d.getFullYear() - 1);
    }
    d.setMonth(newMonth);

    this.ioService.post('/boardandbrush/generateMonthEndCombined', {
      date: d,
      locationId: this.locationService.getActiveLocation()._id,
    }).then((result: any) => {
      console.log(result);
    }).catch((err: any) => {
      console.log(err);
    });
  }

  remoteExport() {
    this.ioService.post('/boardandbrush/remoteExport', {
      locationId: this.locationService.getActiveLocation()._id,
      minId: this.importConfig.minId,
      limit: this.importConfig.limit
    }).then((result: any) => {
      console.log(result);
    }).catch((err: any) => {
      console.log(err);
    });
  }

  addRemotePromo() {
    this.promoConfig.locationId = this.locationService.getActiveLocation()._id;
    this.ioService.post('/boardandbrush/addRemotePromo', this.promoConfig).then((result: any) => {
      console.log(result);
    }).catch((err: any) => {
      console.log(err);
    });
  }

  closeBatch() {
    this.ioService.post('/batch/closeBatch', {
      locationId: this.locationService.getActiveLocation()._id
      // locationId: '5c9d35f658fc3c0324a2eeca' // Gotham City
    }).then((result: any) => {
      console.log(result);
    }).catch((err: any) => {
      console.log(err);
    });
  }


  unimportTransactions() {
    let el: HTMLElement = this.unimportEl.nativeElement;
    el.click();
  }

  onFileChange(event) {
    this.unimportingTransactions = true;
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      this.ioService.upload('/import/unimport-transactions', {
        locationId: this.locationService.getActiveLocation()._id
      }, file).subscribe(event => {
        console.log(event);
      });
    }
  }

}
