import { Injectable } from '@angular/core';
import { Observable, Subject, Subscription } from 'rxjs';

import { AuthenticationService } from '../authentication/authentication.service';
import { IoService } from '../io/io.service';

@Injectable({
  providedIn: 'root'
})
export class AuthorizationService {

  private user;
  private permissions;

  private authenticationStateChanged: Subscription;

  private permissionsLoaded = new Subject<any>();

  constructor(
    private authenticationService: AuthenticationService,
    private ioService: IoService
  ) {
    console.log('Authorization Service constructor()');
    this.authenticationStateChanged = authenticationService.authenticationStateChangedObservable().subscribe(authenticatedId => {
      if (authenticatedId) {
        this.loadPermissions();
      } else {
        this.permissions = null;
        this.permissionsLoaded.next(false);
      }
    });

    if (authenticationService.isSignedIn()) {
      this.loadPermissions();
    }
  }


  loadPermissions() {
    // return new Promise((resolve, reject) => {
      this.ioService.post('/authorization/getPermissions').then((permissionsResponse: any) => {
        console.log('permissionsResponse: ', permissionsResponse);

        this.permissions = permissionsResponse;

        // Index ANY permissions
        let any = [];
        for (let targeted in this.permissions.targeted) {
          if (this.permissions.targeted.hasOwnProperty(targeted)) {
            any = any.concat(this.permissions.targeted[targeted]);
          }
        }
        this.permissions.any = any;
        this.user = permissionsResponse.user;
        this.permissionsLoaded.next(true);
        // console.log('PERMISSIONS LOADED');
        // resolve();
      });
    // });
  }

  permissionsAreLoaded() {
    if (this.permissions) {
      return true;
    } else {
      return false;
    }
  }

  permissionsLoadedObservable(): Observable<any> {
    return this.permissionsLoaded.asObservable();
  }

  checkPermission(permission, target=null) {
    // console.log('permission: ', permission);
    // console.log('target: ', target);
    if (this.permissions) {
        if (target) {
            if (target == 'any') {
                if (this.permissions.any.indexOf(permission) > -1) {
                    return true;
                } else {
                    return false;
                }
            } else {
                if (this.permissions.targeted[target] && ((this.permissions.targeted[target].indexOf(permission) > -1) || (this.permissions.targeted[target].indexOf(true) > -1))) {
                    return true;
                } else {
                    return false;
                }
            }
        } else {
            if (this.permissions.untargeted.indexOf(permission) > -1) {
                return true;
            } else {
                return false;
            }
        }
    } else {
        console.error('Permissions are not yet loaded.');
        return false;
    }
  }

  getUser() {
    return this.user;
  }

}
