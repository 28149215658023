import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

// import { MatDialogModule, MatFormFieldModule, MatButtonModule, MatInputModule } from '@angular/material';
import { MatDialogModule } from '@angular/material';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule } from '@angular/forms';

// Router
import { AppRoutingModule } from '../../app-routing.module';

// Pipes
import { RfPipe } from '../../pipes/rf/rf.pipe';

// Components
import { HeaderComponent } from './header/header.component';
import { SupportComponent } from './support/support.component';
import { MessagingComponent } from './messaging/messaging.component';

import { ConversationComponent } from './../report/conversation/conversation.component';

@NgModule({
  declarations: [
    RfPipe,
    HeaderComponent,
    SupportComponent,
    MessagingComponent,
    ConversationComponent
  ],
  imports: [
    CommonModule,
    MatDialogModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    FormsModule
  ],
  exports: [
    RfPipe,
    HeaderComponent,
    ConversationComponent
  ],
  entryComponents: [
    MessagingComponent
  ]
})
export class SharedModule { }
