import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AppRoutingModule } from '../../app-routing.module';

import { SharedModule } from '../shared/shared.module';

import { AngularMultiSelectModule } from 'angular2-multiselect-dropdown';

import { ScrollingModule } from '@angular/cdk/scrolling';
import { FormsModule } from '@angular/forms';
import { NgxDaterangepickerMd } from 'ngx-daterangepicker-material';
import { NgxMaskModule } from 'ngx-mask';
import { DragulaModule } from 'ng2-dragula';

import { ReportComponent } from './report.component';
import { MenuComponent } from './menu/menu.component';
import { DetailComponent } from './detail/detail.component';
import { ReportDataComponent } from './report-data/report-data.component';
import { FilterDateRangeComponent } from './filter-date-range/filter-date-range.component';
import { DetailPurchaseComponent } from './detail-purchase/detail-purchase.component';
import { DetailLocationComponent } from './detail-location/detail-location.component';
import { DetailUserComponent } from './detail-user/detail-user.component';
import { DetailTransactionComponent } from './detail-transaction/detail-transaction.component';
import { DetailAccountComponent } from './detail-account/detail-account.component';
import { DetailDynamicComponent } from './detail-dynamic/detail-dynamic.component';
import { DetailReportComponent } from './detail-report/detail-report.component';
import { DetailCustomerComponent } from './detail-customer/detail-customer.component';
import { DetailBatchComponent } from './detail-batch/detail-batch.component';
import { DetailInventoryComponent } from './detail-inventory/detail-inventory.component';
import { DetailDiscountComponent } from './detail-discount/detail-discount.component';
import { AddUserComponent } from './add-user/add-user.component';
import { AddLocationComponent } from './add-location/add-location.component';
import { AddReportComponent } from './add-report/add-report.component';
import { DetailFormComponent } from './detail-form/detail-form.component';
import { DetailTaxComponent } from './detail-tax/detail-tax.component';
import { DetailPermissionComponent } from './detail-permission/detail-permission.component';
// import { ConversationComponent } from './conversation/conversation.component';
import { ConversationsComponent } from './conversations/conversations.component';
import { DetailGroupComponent } from './detail-group/detail-group.component';
import { DetailEmployeeComponent } from './detail-employee/detail-employee.component';
import { AddInvoiceComponent } from './add-invoice/add-invoice.component';

@NgModule({
  declarations: [
    MenuComponent,
    DetailComponent,
    ReportComponent,
    ReportDataComponent,
    FilterDateRangeComponent,
    DetailPurchaseComponent,
    DetailLocationComponent,
    DetailUserComponent,
    DetailTransactionComponent,
    DetailAccountComponent,
    DetailDynamicComponent,
    DetailReportComponent,
    DetailCustomerComponent,
    DetailBatchComponent,
    DetailInventoryComponent,
    DetailDiscountComponent,
    AddUserComponent,
    AddLocationComponent,
    AddReportComponent,
    DetailFormComponent,
    DetailTaxComponent,
    DetailPermissionComponent,
    // ConversationComponent,
    ConversationsComponent,
    DetailGroupComponent,
    DetailEmployeeComponent,
    AddInvoiceComponent
  ],
  imports: [
    CommonModule,
    AppRoutingModule,
    SharedModule,
    ScrollingModule,
    AngularMultiSelectModule,
    FormsModule,
    NgxDaterangepickerMd.forRoot(),
    NgxMaskModule.forRoot(),
    DragulaModule.forRoot()
  ],
  entryComponents: [
    AddUserComponent,
    AddLocationComponent,
    AddReportComponent,
    AddInvoiceComponent
  ]
})
export class ReportModule { }
