import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';

import { ReportService } from '../../services/report/report.service';
import { MessageService } from '../../services/message/message.service';

@Component({
  selector: 'app-report',
  templateUrl: './report.component.html',
  styleUrls: ['./report.component.scss']
})
export class ReportComponent implements OnInit {

  conversationActive;
  conversationActiveUpdated: Subscription;

  constructor(
    private reportService: ReportService,
    private messageService: MessageService
  ) { }

  ngOnInit() {
    this.reportService.init();
    // this.router.navigate(['reports', this.activeReportConfig.alias]);
    console.log('report screen init');

    this.conversationActiveUpdated = this.messageService.conversationActiveUpdatedObservable().subscribe(conversationActive => {
      this.conversationActive = conversationActive;
    });

    this.conversationActive = this.messageService.getConversationActive();
  }

  ngOnDestroy() {
    if (this.conversationActiveUpdated) {
      this.conversationActiveUpdated.unsubscribe();
    }
  }

}
