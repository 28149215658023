import { Component, OnInit, OnDestroy } from '@angular/core';

import { Subscription } from 'rxjs';
import { LocationService } from '../../../services/location/location.service';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})
export class MenuComponent implements OnInit {

  activeLocationSet: Subscription;
  location;

  open = false;

  menu = [
    {
      name: 'General',
      alias: 'general',
      active: true,
      options: [
        // {
        //   name: 'Settings',
        //   alias: 'settings'
        // },
        {
          name: 'Notification & Autoresponder',
          alias: 'autoresponder'
        }
      ]
    },
    {
      name: 'Automatic Messages',
      alias: 'automatic',
      active: true,
      options: [
        {
          name: 'Birthday',
          alias: 'birthday'
        },
        {
          name: 'First Purchase Anniversary',
          alias: 'anniversary'
        }
      ]
    }
  ];

  constructor(
    private locationService: LocationService
  ) { }

  ngOnInit() {
    this.activeLocationSet = this.locationService.activeLocationSetObservable().subscribe(locationSet => {
      this.location = locationSet;
      console.log('LOCATION: ', this.location);
    });

    this.location = this.locationService.getActiveLocation();
    console.log('LOCATION: ', this.location);
  }

  ngOnDestroy() {
    if (this.activeLocationSet) {
      this.activeLocationSet.unsubscribe();
    }
  }

  toggleMenu() {
    this.open = !this.open;
  }

  toggleCategory(category) {
    category.active = !category.active;
  }

  setActiveOption(report) {
    event.stopPropagation();
    this.open = false;
  }

}
