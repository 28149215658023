import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { Observable, Subject, Subscription, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';

import { HistoryService } from '../../../services/history/history.service';
import { IoService } from '../../../services/io/io.service';
import { ReportService } from '../../../services/report/report.service';

@Component({
  selector: 'app-detail-group',
  templateUrl: './detail-group.component.html',
  styleUrls: ['./detail-group.component.scss']
})
export class DetailGroupComponent implements OnInit {

  documentId$: Observable<any>;
  documentId;

  showBackButton;

  saving;
  group;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private ioService: IoService,
    private historyService: HistoryService,
    private reportService: ReportService
  ) { }

  ngOnInit() {
    this.documentId$ = this.route.paramMap.pipe(switchMap(params => of(params.get('documentId'))));
    this.documentId$.subscribe(documentId => {
      if (documentId) {
        this.documentId = documentId;
        this.loadGroupDetail();
      }
    });

    if (this.historyService.getPreviousLinkDepth() > 3) {
      this.showBackButton = true;
    }
  }

  close() {
    this.router.navigate(['../../'], { relativeTo: this.route });
  }

  loadGroupDetail() {
    this.group = null;

    this.ioService.post('/group/getGroup', {
      groupId: this.documentId
    }).then((groupResponse: any) => {
      console.log('groupResponse: ', groupResponse);

      this.group = groupResponse.group;
    });
  }

  saveGroup() {
    if (!this.saving) {
      console.log('save group');
      this.saving = true;

      let updateGroup = JSON.parse(JSON.stringify(this.group));

      this.ioService.post('/group/updateGroup', {
        group: updateGroup
      }).then((updateResponse: any) => {
        console.log('updateResponse: ', updateResponse);
        this.saving = false;
        this.reportService.loadReport(0);
      });
    }
  }

}
