import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpEvent, HttpErrorResponse, HttpEventType } from  '@angular/common/http';

import { environment } from '../../../environments/environment';

import { AuthenticationService } from '../authentication/authentication.service';

@Injectable({
  providedIn: 'root'
})
export class IoService {

  constructor(
    private http: HttpClient,
    private authenticationService: AuthenticationService
  ) { }

  post(path: string, postData = {}) {
    return new Promise((resolve, reject) => {
      if (this.authenticationService.isSignedIn()) {
        const httpOptions = {
          headers: new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': 'Bearer '+localStorage.getItem('token')
          })
        };

        this.http.post<any>(environment.serverUrl+environment.clientId+path, postData, httpOptions).toPromise()
          .then(response => {
            resolve(response);
          })
          .catch(err => {
            reject(err);
          });
      } else {
        reject('Expired authorization.');
      }
    });
  }

  upload(path: string, postData, file) {
    // return new Promise((resolve, reject) => {
      // if (this.authenticationService.isSignedIn()) {
        // Build FormData object
        let formData = new FormData();
        formData.append('file', file);
        formData.append('data', JSON.stringify(postData));

        // Define post headers
        const headers = new HttpHeaders({
          // 'Content-Type': 'multipart/form-data',
          'Authorization': 'Bearer '+localStorage.getItem('token')
        });

        console.log('path: ', path);
        console.log('postData: ', postData);
        console.log('file: ', file);

        return this.http.post<any>(environment.serverUrl+environment.clientId+path, formData, {
          headers,
          reportProgress: true,
          observe: 'events'
        });

      // } else {
      //   // reject('Expired authorization.');
      //   return false;
      // }
    // });
  }

  download(path: string, postData = {}, filename: string = 'report.csv') {
    return new Promise((resolve, reject) => {
      if (this.authenticationService.isSignedIn()) {
        const headers = new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': 'Bearer '+localStorage.getItem('token')
        });

        this.http.post<any>(environment.serverUrl+environment.clientId+path, postData, {
          headers,
          responseType: 'blob' as 'json'
        })
          .toPromise()
          .then(response => {

            if (navigator.msSaveBlob) {
                 // IE 10+
                navigator.msSaveBlob(new Blob([response], { type: 'text/csv;charset=utf-8;' }), filename);
            } else {
                var saveByteArray = (function () {
                    var a = document.createElement("a");
                    document.body.appendChild(a);
                    // a.style = "display: none";
                    return function (data, name) {
                        var blob = new Blob(data, {type: "octet/stream"}),
                            url = window.URL.createObjectURL(blob);
                        a.href = url;
                        a.download = name;
                        a.click();
                        window.URL.revokeObjectURL(url);
                    };
                }());
                saveByteArray([response], filename);
            }

            resolve();
          })
          .catch(err => {
            reject(err);
          });
      } else {
        reject('Expired authorization.');
      }
    });
  }
}
