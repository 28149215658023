import { Component } from '@angular/core';

import { HistoryService } from './services/history/history.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'InfraRed 2.2.0';

  constructor(historyService: HistoryService) {
    historyService.loadRouting();
  }
}
