import { Component, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA} from '@angular/material';

import { IoService } from '../../../services/io/io.service';
import { LocationService } from '../../../services/location/location.service';

@Component({
  selector: 'app-add-invoice',
  templateUrl: './add-invoice.component.html',
  styleUrls: ['./add-invoice.component.scss']
})
export class AddInvoiceComponent implements OnInit {

  email;
  amount;
  description;
  emailError;
  amountError;

  constructor(
    public dialogRef: MatDialogRef<AddInvoiceComponent>,
    private ioService: IoService,
    private locationService: LocationService
  ) { }

  ngOnInit() {
  }

  close() {
    this.dialogRef.close();
  }

  send() {
    let input = {
      email: this.email,
      amount: this.amount,
      description: this.description,
      locationId: this.locationService.getActiveLocation()._id
    };

    console.log('SEND Invoice: ', input);

    // Validation
    this.emailError = null;
    this.amountError = null;
    if (!this.emailIsValid(this.email)) {
      this.emailError = true;
    }

    if (isNaN(this.amount)) {
      this.amountError = true;
    }

    if (this.emailError == null && this.amountError == null) {
      // Send request to server
      this.ioService.post('/transaction/invoice/add', input).then((transactionResponse: any) => {
        console.log('transactionResponse: ', transactionResponse);

        this.dialogRef.close(transactionResponse.transactionId);
      }).catch((transactionError: any) => {
        console.log('transactionError: ', transactionError);
      });
    }

  }

  emailIsValid(email) {
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
  }

}
