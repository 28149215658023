import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { Observable, Subject, Subscription, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';

import { IoService } from '../../../services/io/io.service';

@Component({
  selector: 'app-detail-report',
  templateUrl: './detail-report.component.html',
  styleUrls: ['./detail-report.component.scss']
})
export class DetailReportComponent implements OnInit {

    documentId$: Observable<any>;
    documentId;

    report;

    constructor(
      private route: ActivatedRoute,
      private router: Router,
      private ioService: IoService
    ) { }

    ngOnInit() {
      this.documentId$ = this.route.paramMap.pipe(switchMap(params => of(params.get('documentId'))));
      this.documentId$.subscribe(documentId => {
        if (documentId) {
          this.documentId = documentId;
          this.loadReportDetail();
        }
      });
    }

    close() {
      this.router.navigate(['../../'], { relativeTo: this.route });
    }

    loadReportDetail() {
      console.log('loadReportDetail: ');

    }

}
