import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { Observable, Subject, Subscription, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';

import { HistoryService } from '../../../services/history/history.service';
import { IoService } from '../../../services/io/io.service';
import { ReportService } from '../../../services/report/report.service';

@Component({
  selector: 'app-detail-permission',
  templateUrl: './detail-permission.component.html',
  styleUrls: ['./detail-permission.component.scss']
})
export class DetailPermissionComponent implements OnInit {

  documentId$: Observable<any>;
  documentId;

  showBackButton;

  saving;
  permission;

  groups = {
    available: [],
    selected: [],
    settings: {
      singleSelection: false,
      text: 'Select Groups',
      selectAllText:'Select All',
      unSelectAllText: 'Deselect All',
      enableSearchFilter: true,
      addNewItemOnFilter: true,
      classes: 'myclass multiselect'
    },
    add: (data: string) => {
      let newItem = {
        id: this.groups.available.length+1,
        itemName: data,
        name: data
      };
      this.groups.available.push(newItem);
      this.groups.selected.push(newItem);
    }
  };

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private ioService: IoService,
    private historyService: HistoryService,
    private reportService: ReportService
  ) { }

  ngOnInit() {
    this.documentId$ = this.route.paramMap.pipe(switchMap(params => of(params.get('documentId'))));
    this.documentId$.subscribe(documentId => {
      if (documentId) {
        this.documentId = documentId;
        this.loadPermissionDetail();
      }
    });

    if (this.historyService.getPreviousLinkDepth() > 3) {
      this.showBackButton = true;
    }
  }

  close() {
    this.router.navigate(['../../'], { relativeTo: this.route });
  }

  loadPermissionDetail() {

    this.groups.selected = [];
    this.groups.available = [];

    this.ioService.post('/permission/getPermission', {
      permissionId: this.documentId,
      getGroups: true
    }).then((permissionResponse: any) => {
      console.log('permissionResponse: ', permissionResponse);

      // Prepare Groups
      if (permissionResponse.groups) {
        let groupIndex = 0;
        for (let group of permissionResponse.groups) {
          groupIndex++;
          let groupObject = {
            id: groupIndex,
            itemName: group
          };
          this.groups.available.push(groupObject);
          if (permissionResponse.permission.groups && permissionResponse.permission.groups.indexOf(group) != -1) {
            this.groups.selected.push(groupObject);
          }
        }
      }

      this.permission = permissionResponse.permission;

    });
  }

  savePermission() {
    if (!this.saving) {
      console.log('save permission');
      this.saving = true;

      let updatePermission = JSON.parse(JSON.stringify(this.permission));

      updatePermission.groups = this.groups.selected.map(a => a.itemName);

      this.ioService.post('/permission/updatePermission', {
        permission: updatePermission
      }).then((updateResponse: any) => {
        console.log('updateResponse: ', updateResponse);
        this.saving = false;
        this.reportService.loadReport(0);
      });
    }
  }

  grantToRoot() {
    this.ioService.post('/authorization/authorizeRoot', {
      permission: this.permission.permission,
      targeted: this.permission.targeted
    }).then((updateResponse: any) => {
      console.log('Root Authorized');
    });
  }

}
