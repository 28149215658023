import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import { environment } from '../../../../environments/environment';

import { AuthenticationService } from '../../../services/authentication/authentication.service';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {

  clientId;
  validInput;
  processing;
  error;
  done;

  userKey;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private authenticationService: AuthenticationService
  ) { }

  ngOnInit() {
    this.clientId = environment.clientId;
  }

  updateUserKey(event: any) {
    this.userKey = event.target.value;
    this.checkValid();
  }


  checkValid() {
    if (this.userKey.length > 7) {
      this.validInput = true;
    } else {
      this.validInput = false;
    }
  }

  sendResetRequest() {
    this.error = null;
    if (this.validInput && !this.processing) {
      // console.log('handle: '+this.userKey);
      this.processing = true;
      this.authenticationService.requestPasswordReset(this.userKey)
        .then(() => {
          this.done = true;
          // this.router.navigate(['/sign-in']);
        })
        .catch(resetError => {
          this.processing = false;
          this.error = resetError;
        });
    } else {
      if (!this.validInput) {
        // this.error = 'Invalid email or phone.';
      }
    }
  }

  closeWindow() {
    window.close();
  }

}
