import { Component, OnInit } from '@angular/core';

import * as moment from 'moment';

import { ReportService } from '../../../services/report/report.service';

@Component({
  selector: 'app-filter-date-range',
  templateUrl: './filter-date-range.component.html',
  styleUrls: ['./filter-date-range.component.scss']
})
export class FilterDateRangeComponent implements OnInit {

  dateRange;

  ranges: any = {
    'All Time': [
      moment().subtract(15, 'years'),
      moment().endOf('month')
    ],
    Today: [
      moment().startOf('day'),
      moment().endOf('day')
    ],
    Yesterday: [
      moment().subtract(1, 'days').startOf('day'),
      moment().subtract(1, 'days').endOf('day')
    ],
    // 'Last 7 Days': [moment().subtract(6, 'days'), moment()],
    // 'Last 30 Days': [moment().subtract(29, 'days'), moment()],
    'Last Month': [
      moment()
        .subtract(1, 'month')
        .startOf('month'),
      moment()
        .subtract(1, 'month')
        .endOf('month')
    ],
    'This Month': [
      moment().startOf('month'),
      moment().endOf('month')
    ]
  };

  constructor(
    private reportService: ReportService
  ) {
    // this.dateRange = reportService.getDateRange();
  }

  ngOnInit() {
    this.dateRange = this.reportService.getDateRange();
  }

  datesUpdated(range) {
    if (range && (range.startDate || range.endDate)) {
      this.reportService.setDateRange(range);
    }
  }

}
