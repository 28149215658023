import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators, ReactiveFormsModule } from '@angular/forms';

import { environment } from '../../../../environments/environment';

import { AuthenticationService } from '../../../services/authentication/authentication.service';
import { AuthorizationService } from '../../../services/authorization/authorization.service';

@Component({
  selector: 'app-sign-in',
  templateUrl: './sign-in.component.html',
  styleUrls: ['./sign-in.component.scss']
})
export class SignInComponent implements OnInit {
  clientId;
  signInForm: FormGroup;
  loading = false;
  submitted = false;
  returnUrl: string;
  error;

  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private authenticationService: AuthenticationService,
    private authorizationService: AuthorizationService
  ) {
    if (authenticationService.isSignedIn) {
      this.router.navigate(['/']);
    }
  }

  ngOnInit() {
    this.clientId = environment.clientId;

    this.signInForm = this.formBuilder.group({
      email: ['', Validators.required],
      password: ['', Validators.required]
    });

    // Capture where the user was redirected from
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
  }

  get field() {
    return this.signInForm.controls;
  }

  onSubmit() {
    this.submitted = true;
    this.error = null;

    if (this.signInForm.invalid) {
      this.error = 'Email/Phone and password are required.';
      return;
    }

    this.loading = true;

    this.authenticationService.signin(this.field.email.value, this.field.password.value)
      .then(() => {
        // this.router.navigate([this.returnUrl]);
        console.log('router navigate to root');
        this.router.navigate(['/']);
      })
      .catch(signinError => {
        this.error = signinError.error.msg;
      })
  }

}
