import { Component, OnInit, OnDestroy } from '@angular/core';
import { Location } from '@angular/common';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { Observable, Subject, Subscription, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';

// import * as moment from 'moment';

import { HistoryService } from '../../../services/history/history.service';
import { IoService } from '../../../services/io/io.service';
import { AuthorizationService } from '../../../services/authorization/authorization.service';
import { ReportService } from '../../../services/report/report.service';
import { MessageService } from '../../../services/message/message.service';

@Component({
  selector: 'app-detail-transaction',
  templateUrl: './detail-transaction.component.html',
  styleUrls: ['./detail-transaction.component.scss']
})
export class DetailTransactionComponent implements OnInit, OnDestroy {

    documentId$: Observable<any>;
    documentId;

    showBackButton;

    transaction;

    refunding;
    refundAmount;
    refundRequestAmount;

    sendingReceipt;

    invoiceLinkCopied;
    invoiceLinkCopyText = 'Copy Invoice Link';

    showStatusDetails;

    avsResponseCodes = {
      X: 'Match of address and 9-digit zip code',
      Y: 'Match of address and 5-digit zip code',
      W: 'Match of 9-digit zip code; address does not match',
      Z: 'Match of 5-digit zip code; address does not match',
      A: 'Address: Address Matches ZIP Does Not Match',
      N: 'No: Address and ZIP Do Not Match',
      G: 'Address information not verified',
      S: 'Service Not Supported: Issuer does not support address verification',
      U: 'Address information is unavailable',
      E: 'Error: Transaction ineligible for address verification',
      R: 'Retry: System Unavailable or Timeout'
    };

    cscResponseCodes = {
      M: 'The CSC matches the issuing bank’s records',
      N: 'The CSC does not match the issuing bank’s records',
      P: 'The CSC was not processed',
      S: 'The card should have a CSC, but merchant indicated it was not present',
      U: 'Card issuing bank does not participate',
      X: 'Unknown / No response'
    };

    private permissionsLoaded: Subscription;

    conversationActive;
    conversationActiveUpdated: Subscription;

    constructor(
      private location: Location,
      private route: ActivatedRoute,
      private router: Router,
      private historyService: HistoryService,
      private ioService: IoService,
      private authorizationService: AuthorizationService,
      private reportService: ReportService,
      private messageService: MessageService
    ) { }

    ngOnInit() {
      this.documentId$ = this.route.paramMap.pipe(switchMap(params => of(params.get('documentId'))));
      this.documentId$.subscribe(documentId => {
        if (documentId) {
          this.documentId = documentId;
          this.loadTransactionDetail();
        }
      });

      if (this.historyService.getPreviousLinkDepth() > 3) {
        this.showBackButton = true;
      }

      this.conversationActiveUpdated = this.messageService.conversationActiveUpdatedObservable().subscribe(conversationActive => {
        this.conversationActive = conversationActive;
      });
      this.conversationActive = this.messageService.getConversationActive();
    }

    ngOnDestroy() {
      if (this.permissionsLoaded) {
        this.permissionsLoaded.unsubscribe();
      }

      if (this.conversationActiveUpdated) {
        this.conversationActiveUpdated.unsubscribe();
      }
    }

    close() {
      this.router.navigate(['../../'], { relativeTo: this.route });
    }

    back() {
      this.location.back();
    }

    loadTransactionDetail() {
      console.log('loadTransactionDetail: ');
      this.transaction = null;
      // this.showStatusDetails = null;
      this.refunding = null;
      this.refundAmount = null;
      this.refundRequestAmount = null;

      this.ioService.post('/transaction/getDetails', {
        transactionId: this.documentId
      }).then((transactionResponse: any) => {
        console.log('transactionResponse: ', transactionResponse);
        if (transactionResponse.transaction.shipping == []) {
          transactionResponse.transaction.shipping = null;
        } else {
          console.log('shipping: ', transactionResponse.transaction.shipping);
        }

        // Define Card Type
        if (transactionResponse.transaction.type.toLowerCase() == 'gift') {
          transactionResponse.transaction.displayType = 'Gift Card';
        } else if (transactionResponse.transaction.type == 'cash') {
          transactionResponse.transaction.displayType = "Cash";
        } else if (transactionResponse.transaction.type == 'invoice') {
          transactionResponse.transaction.displayType = "E-Invoice";
          transactionResponse.transaction.amount = transactionResponse.transaction.attemptedAmount;
        } else if (transactionResponse.transaction.type == 'reward') {
          transactionResponse.transaction.displayType = "Reward Code";
        } else if (transactionResponse.transaction.type == 'checkin') {
          transactionResponse.transaction.displayType = 'Check-In';
        } else {
          transactionResponse.transaction.displayType = transactionResponse.transaction.cardType;
          if (!transactionResponse.transaction.displayType) {
            transactionResponse.transaction.displayType = "Credit Card";
          }
        }

        if (transactionResponse.transaction.success == false && transactionResponse.transaction.responseDetails && transactionResponse.transaction.responseDetails.msg) {
          transactionResponse.transaction.reason = transactionResponse.transaction.responseDetails.msg;
        }

        if (transactionResponse.transaction.responseDetails && transactionResponse.transaction.responseDetails.avsResultCode) {
          transactionResponse.transaction.responseDetails.avsResultDescription = this.avsResponseCodes[transactionResponse.transaction.responseDetails.avsResultCode];
        }

        if (transactionResponse.transaction.responseDetails && transactionResponse.transaction.responseDetails.cscResultCode) {
          transactionResponse.transaction.responseDetails.cscResultDescription = this.cscResponseCodes[transactionResponse.transaction.responseDetails.cscResultCode];
        }


        // Get purchaseId on checkins
        if (transactionResponse.transaction.seatKey) {
          let seatParts = transactionResponse.transaction.seatKey.split('-');
          console.log('seatParts: ', seatParts);

          transactionResponse.transaction.purchaseId = seatParts[0];
        }




        this.transaction = transactionResponse.transaction;





          // Make sure this user can refund credit card transactions here
          if (this.authorizationService.permissionsAreLoaded()) {
            this.checkTransactionPermissions();
          } else {
            this.permissionsLoaded = this.authorizationService.permissionsLoadedObservable().subscribe(() => {
              this.checkTransactionPermissions();
            });
          }

      });
    }

    checkTransactionPermissions() {
      if (!this.transaction.importTime && this.transaction.amount < 0 && this.transaction.responseDetails && this.transaction.responseDetails.transactionId && this.dateDiffDays(new Date(this.transaction.time), new Date()) < 89) {
        // If this transaction has not yet been refunded or the active user is an admin of this location
        if (!this.transaction.timeRefunded || this.authorizationService.checkPermission('location.admin', this.transaction.locationId)) {
          this.transaction.canRefund = this.authorizationService.checkPermission('location.ledger.'+this.transaction.type+'.add', this.transaction.locationId);
        }



        if (this.transaction.type == 'credit') {
          this.transaction.canSendReceipt = this.authorizationService.checkPermission('location.ledger.'+this.transaction.type+'.receipt', this.transaction.locationId);
          this.transaction.canVoid = this.authorizationService.checkPermission('location.ledger.'+this.transaction.type+'.void', this.transaction.locationId);
        }
      }

      if (this.transaction.type == 'invoice') {
        this.transaction.canCancelInvoice = this.authorizationService.checkPermission('location.ledger.invoice.subtract', this.transaction.locationId);
        this.transaction.canSendInvoice = this.authorizationService.checkPermission('location.ledger.invoice.add', this.transaction.locationId);
      }

      console.log('checking permission: '+'ledger.'+this.transaction.ledgerId+'.viewAccountDetails');
      this.transaction.viewAccountDetails = this.authorizationService.checkPermission('ledger.'+this.transaction.ledgerId+'.viewAccountDetails');
    }

    showAccountDetails() {
      console.log('showAccountDetails');
      if (this.transaction.viewAccountDetails && !this.transaction.fullCardNumber) {
        console.log('User is allowed to view account details');
        this.transaction.cardNumberLoading = true;
        this.ioService.post('/account/getAccountDetail', {
          accountId: this.transaction.accountId
        }).then((accountResponse: any) => {
          console.log('accountResponse: ', accountResponse);
          this.transaction.cardNumberLoading = false;
          if (accountResponse.account.cardNumbers && accountResponse.account.cardNumbers.length > 0) {
            this.transaction.fullCardNumber = accountResponse.account.cardNumbers[0];
          }

          this.transaction.accountTransactions = accountResponse.account.transactions;

        });
      }
    }

    loadDetail(type, id) {
      this.router.navigate(['../../'+type+'/'+id], { relativeTo: this.route });
    }


    toggleStatusDetails() {
      this.showStatusDetails = !this.showStatusDetails;
    }


    dateDiffDays(dt1, dt2) {
      return Math.floor((Date.UTC(dt2.getFullYear(), dt2.getMonth(), dt2.getDate()) - Date.UTC(dt1.getFullYear(), dt1.getMonth(), dt1.getDate()) ) /(1000 * 60 * 60 * 24));
    }

    voidClick() {
      this.ioService.post('/transaction/'+this.transaction.type+'/void', {
        transactionId: this.transaction._id,
        locationId: this.transaction.locationId
      }).then((voidResponse: any) => {
        console.log('voidResponse: ', voidResponse);
        this.router.navigate(['../../'], { relativeTo: this.route });
        this.reportService.loadReport(null);
      });
    }

    refundClick() {
      this.refundAmount = (this.transaction.amount*-1/100).toFixed(2);

    }

    refundRequest() {
      this.verifyRefundAmount();
      this.refundRequestAmount = Number(this.refundAmount).toFixed(2);
    }

    refundCancel() {
      this.refundRequestAmount = null;
    }

    refund() {
      if (!this.refunding) {
        this.refunding = true;
        this.ioService.post('/transaction/'+this.transaction.type+'/add', {
          transactionId: this.transaction._id,
          locationId: this.transaction.locationId,
          amount: this.refundRequestAmount
        }).then((refundResponse: any) => {
          console.log('refundResponse: ', refundResponse);
          this.router.navigate(['../../'], { relativeTo: this.route });
          this.reportService.loadReport(null);
        });
      }
    }

    verifyRefundAmount() {
      if (this.refundAmount > this.transaction.amount*-1/100) {
        this.refundAmount = this.transaction.amount*-1/100;
      }
      if (this.refundAmount <= 0) {
        this.refundAmount = this.transaction.amount*-1/100;
      }
    }

    sendReceipt() {
      if (!this.sendingReceipt) {
        this.sendingReceipt = true;
        this.ioService.post('/transaction/'+this.transaction.type+'/receipt', {
          transactionId: this.transaction._id,
          locationId: this.transaction.locationId
        }).then((receiptResponse: any) => {
          console.log('receiptResponse: ', receiptResponse);
        });
      }
    }

    cancelInvoice() {
      this.ioService.post('/transaction/invoice/subtract', {
        transactionId: this.transaction._id,
        locationId: this.transaction.locationId,
        amount: -1
      }).then((transactionResponse: any) => {
        console.log('transactionResponse: ', transactionResponse);
        this.loadTransactionDetail();

      }).catch((transactionError: any) => {
        console.log('transactionError: ', transactionError);
      });
    }

    resendInvoice() {
      this.ioService.post('/transaction/invoice/add', {
        transactionId: this.transaction._id,
        locationId: this.transaction.locationId,
        amount: this.transaction.attemptedAmount
      }).then((transactionResponse: any) => {
        console.log('transactionResponse: ', transactionResponse);
        this.close();
      }).catch((transactionError: any) => {
        console.log('transactionError: ', transactionError);
      });
    }

    openInvoiceExternally() {
      window.open("https://bbeinvoice.redfirepay.com?locationId="+this.transaction.locationId+"&invoiceKey="+this.transaction.invoiceKey, "_blank");
    }

    copyInvoiceLink() {
      const selBox = document.createElement('textarea');
      selBox.style.position = 'fixed';
      selBox.style.left = '0';
      selBox.style.top = '0';
      selBox.style.opacity = '0';
      selBox.value = "https://bbeinvoice.redfirepay.com?locationId="+this.transaction.locationId+"&invoiceKey="+this.transaction.invoiceKey;
      document.body.appendChild(selBox);
      selBox.focus();
      selBox.select();
      document.execCommand('copy');
      document.body.removeChild(selBox);

      this.invoiceLinkCopied = true;
      let originalBtnText = this.invoiceLinkCopyText;
      this.invoiceLinkCopyText = 'Copied!';
      setTimeout(() => {
        this.invoiceLinkCopied = false;
        this.invoiceLinkCopyText = originalBtnText;
      }, 3000);
    }

}
