import { Component, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA} from '@angular/material';

import { IoService } from '../../../services/io/io.service';
import { LocationService } from '../../../services/location/location.service';

@Component({
  selector: 'app-add-user',
  templateUrl: './add-user.component.html',
  styleUrls: ['./add-user.component.scss']
})
export class AddUserComponent implements OnInit {

  newUserEmail: string;
  validEmail: boolean;

  constructor(
    public dialogRef: MatDialogRef<AddUserComponent>,
    private ioService: IoService,
    private locationService: LocationService
  ) { }

  ngOnInit() {
  }

  setNewUserEmail(event: any) {
    this.newUserEmail = event.target.value;
    if (this.newUserEmail.length > 5) {
      this.validEmail = true;
    }
  }

  addUserLocation() {
    this.ioService.post('/user/addUserLocation', {
      email: this.newUserEmail,
      locationId: this.locationService.getActiveLocation()._id
    }).then((userId: any) => {
      console.log('addUserResponse: ', userId);
      this.dialogRef.close(userId);
    });
  }

}
