import { Component, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA} from '@angular/material';

import { IoService } from '../../../services/io/io.service';

@Component({
  selector: 'app-add-location',
  templateUrl: './add-location.component.html',
  styleUrls: ['./add-location.component.scss']
})
export class AddLocationComponent implements OnInit {

  newLocationName: string;
  validName: boolean;

  constructor(
    public dialogRef: MatDialogRef<AddLocationComponent>,
    private ioService: IoService
  ) { }

  ngOnInit() {
  }

  setNewLocationName(event: any) {
    this.newLocationName = event.target.value;
    if (this.newLocationName.length > 2) {
      this.validName = true;
    }
  }

  addLocation() {
    this.ioService.post('/location/add', {
      name: this.newLocationName
    }).then((locationId: any) => {
      this.dialogRef.close(locationId);
    });
  }

}
